import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pullAt } from 'lodash';
import { VOUCHER_INITIAL_STATE } from 'Constant/Voucher';

const initialState: any = { ...VOUCHER_INITIAL_STATE };

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    updateVoucher: (state, data: PayloadAction<any>) => {
      const { name, voucherCode } = data.payload;
      let voucherCodes = state.voucherCodes;
      voucherCode.index = voucherCodes.length ? voucherCodes.length + 1 : 0;
      voucherCodes = [...voucherCodes, voucherCode];

      state.name = name;
      state.voucherCodes = voucherCodes;
    },
    deleteVoucherCode: (state, data: PayloadAction<any>) => {
      const voucherCode = data.payload;
      const voucherCodes = state.voucherCodes;
      if (voucherCode.index !== undefined) {
        pullAt(voucherCodes, [voucherCode.index]);
      }

      state.voucherCodes = voucherCodes;
    },
    resetData: (state) => {
      state = { ...VOUCHER_INITIAL_STATE };
      return state;
    },
  },
});

export const { resetData, updateVoucher, deleteVoucherCode } = voucherSlice.actions;
export default voucherSlice.reducer;
