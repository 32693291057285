import React, { useEffect, useState } from 'react';
import { Navbar, Container, Button, Image } from 'react-bootstrap';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { flatMap, map, find, some } from 'lodash';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { MENUS } from 'Constant/Menu';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { logout } from 'App/Features/Auth';
import { handleCheckDynamicRoutes } from 'Utils/Route';
import { default as LogoutConfirmationModal } from 'Components/Common/Modal';
import Logo from 'Static/Images/logo.png';

import { setTitlePage } from 'App/Features/Layout';

const Header = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    auth: { email },
    layout: { titlePage },
  } = useAppSelector((state) => state);

  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);

  const handleCheckMatchLink = () => {
    let title = '';
    const flatMenus = flatMap(MENUS, (menu) => map(menu.children, (sub) => ({ ...sub })));
    const onSideBarCondition = some(flatMenus, (menu) => menu.slug === location.pathname);
    if (onSideBarCondition) {
      title = find(flatMenus, (menu) => menu.slug === location.pathname)?.title ?? '';
    } else {
      title = handleCheckDynamicRoutes(location)?.title ?? '';
    }

    dispatch(setTitlePage(title));
  };

  const handleLogout = async () => {
    setShowLogoutConfirmationModal(false);
    dispatch(logout());
    history.push('admin/login');
  };

  useEffect(() => {
    handleCheckMatchLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <LogoutConfirmationModal
        show={showLogoutConfirmationModal}
        onCloseModal={() => setShowLogoutConfirmationModal(false)}
        onSubmitModal={handleLogout}
        cancelText="Hủy bỏ"
        confirmText="Đồng ý"
        title="Xác nhận đăng xuất"
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
      >
        <div>Bạn có chắc chắn muốn đăng xuất?</div>
      </LogoutConfirmationModal>
      <Navbar className="header-top header-bg">
        <Container fluid>
          <div className="d-flex align-items-center w-100">
            <div className="px-sm-2 px-0 sidebar">
              <Link
                to="/"
                className="w-100 d-flex align-items-center text-white text-decoration-none fw-bold"
              >
                <span className="fs-14 d-none d-sm-inline">
                  <Image src={Logo} className="img-logo ps-4" />
                </span>
              </Link>
            </div>
            <div className="p-0 d-flex w-100">
              <Navbar.Brand href="" className="text-white ps-3 fs-16 fw-bold">
                {titlePage}
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <div className="text-white">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="ms-2 me-3 text-truncate email">{email}</div>
                  </div>
                </div>
                <Button
                  variant="link"
                  onClick={() => setShowLogoutConfirmationModal(true)}
                  className="text-white me-3 logout"
                >
                  <BoxArrowRight /> Đăng xuất
                </Button>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
