import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { login } from 'App/Features/Auth';
import { useAppDispatch } from 'App/Store';
import _ from 'lodash';
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { emailValidation } from 'Utils/Validation';
import * as yup from 'yup';
import MessageError from 'Components/Common/MessageError';
import useMessage from 'Hooks/useMessage';
import { RoleNames } from 'Constant/Roles';
import { AuthApi } from 'Datasource/Auth';
interface ILoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: emailValidation({
    required: 'no fill out of email field',
    email: 'valid email address',
  }),
  password: yup.string().required('Vui lòng nhập.'),
});

export default function LoginForm(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ILoginForm>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const onSubmit = async (params: ILoginForm) => {
    if (!_.isEmpty(errors)) return;
    try {
      const result = await AuthApi.loginApi(params);
      const { data } = result;
      dispatch(login(data));
      if (_.includes(RoleNames, data.role)) {
        dispatch(login(data));
        history.push('/admin/campaigns');
      } else {
        console.log('error');
        openMessage({
          message: 'Địa chỉ email đăng nhập hoặc mật khẩu của bạn không khớp.',
          variant: 'error',
        });
      }
    } catch (error) {
      console.log(error);
      openMessageError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="public-card-width">
      <FormGroup className="mb-3">
        <FormLabel>Địa chỉ e-mail đăng nhập</FormLabel>
        <FormControl
          {...register('email')}
          disabled={isSubmitting}
          type="text"
          aria-label="email"
          aria-describedby="input--email"
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>Mật khẩu</FormLabel>
        <FormControl
          {...register('password')}
          disabled={isSubmitting}
          type="password"
          aria-label="password"
          aria-describedby="input--password"
          value={watch('password') ?? ''}
          onChange={({ target: { value } }) => setValue('password', value)}
        />
      </FormGroup>

      <FormGroup className="d-flex justify-content-end">
        <Link to="/forgot-password" className="white always:color-white">
          Nếu bạn quên mật khẩu, bấm vào đây
        </Link>
      </FormGroup>
      <FormGroup className="mt-3">
        {!_.isEmpty(errors) && (
          <MessageError message={'Địa chỉ email đăng nhập hoặc mật khẩu của bạn không khớp.'} />
        )}
      </FormGroup>
      <FormGroup className="mb-3 d-flex justify-content-center mt-4">
        <Button
          type="submit"
          className="bg-black border-0 d-flex align-items-center justify-content-center"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <Spinner animation="border" role="status" variant="light" size="sm" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <p>Đăng nhập</p>
        </Button>
      </FormGroup>
    </form>
  );
}
