import React from 'react';
import { Table } from 'react-bootstrap';
import { find, isEmpty } from 'lodash';
import { PrizeProbability } from 'Types/Page/Campaign/RegisterCampaign';
import TableRowProbability from './TableRowProbability';
import { prizePickType } from 'Constant/Campaign';

interface TableProps {
  handleEdit: (item: any) => void;
  handleRemove: (item: any) => void;
  prizeProbabilities: PrizeProbability[];
  isDisabledItem?: boolean;
}

const TableProbabilities = ({
  handleRemove,
  handleEdit,
  prizeProbabilities,
}: TableProps): JSX.Element => {
  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-10">Đối tượng</th>
            <th className="w-10">Số lượng</th>
            <th className="w-10">Xác suất</th>
            <th className="w-25">Thời gian bắt đầu</th>
            <th className="w-25">Thời gian kết thúc</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(prizeProbabilities) ? (
            <tr>
              <td className="text-center align-middle" colSpan={6}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            prizeProbabilities.map((value, index) => {
              const pickType = find(prizePickType, (option) => option.value === value.pickType);
              return (
                <TableRowProbability
                  key={`item-${index}`}
                  pickType={pickType?.label}
                  quantity={value.quantity}
                  probability={value?.probability}
                  startDate={value?.startDate}
                  endDate={value?.endDate}
                  handleEditItem={() => handleEdit({ ...value })}
                  handleRemoveItem={() => handleRemove({ ...value })}
                />
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(TableProbabilities);
