import React from 'react';
import { ToastContainer, TypeOptions } from 'react-toastify';

export interface MessageProps {
  variant?: TypeOptions;
  message?: string | React.ReactNode;
}

export default function Message(): JSX.Element {
  const DELAY = 5000;

  return (
    <ToastContainer
      position={'top-right'}
      autoClose={DELAY}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
