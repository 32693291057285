import React, { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';

export interface SpinnerProps {
  isLoading: boolean;
}

export default function SpinnerComponent(props: PropsWithChildren<SpinnerProps>): JSX.Element {
  const { isLoading } = props;
  return (
    <div className={`spinner-component ${isLoading || `visually-hidden`}`}>
      <Spinner animation="border" role="status" style={{ color: 'white' }} size="sm" />
    </div>
  );
}
