import React from 'react';
import { Col } from 'react-bootstrap';
import { FormLabelProps } from 'Components/Common/Form/FormLabel/form-label';

export const labelRequire = (label: string, classLabel?: string): JSX.Element | string => {
  if (label && label.includes('*')) {
    const title = label.split('*')[0].trim();
    return (
      <span className={`${classLabel}`}>
        {title} <span className="txt-require">*</span>
      </span>
    );
  }

  return label;
};

export default function FormLabel(props: FormLabelProps): JSX.Element {
  const { label, classForm, colMd, classLabel } = props;

  return (
    <Col md={colMd} className={`${classForm}`}>
      {labelRequire(label, classLabel)}
    </Col>
  );
}
