import React from 'react';

interface Props {
  title: string | null;
  children: JSX.Element | JSX.Element[];
  classBox?: string;
  colorBox?: string;
  extraText?: string;
  isGray?: boolean;
}

export default function BoxForm(props: Props): JSX.Element {
  const { title, children, classBox, colorBox, extraText, isGray } = props;

  return (
    <div className={`main-wrap-form ${classBox}`}>
      <div
        className={`box-title-header bg-dark text-white title-header-spacing rounded-top-1 ${
          isGray && 'bg-gray-dark'
        }`}
      >
        <div className="d-flex">
          {colorBox && <div className="tag-color" style={{ background: colorBox }} />}
          {title}
        </div>
        <div>{extraText && <p className="text-end ms-4">{extraText}</p>}</div>
      </div>
      <div className="box-form-content card-body">{children}</div>
    </div>
  );
}
