import React, { useEffect, useRef, useState } from 'react';
import StepRegister from 'Components/Page/Campaign/RegisterCampaign/Common/Step';
import { useAppSelector, useAppDispatch } from 'App/Store';
import {
  CampaignSteps,
  changeStep,
  resetData,
  updateStep1State,
  updateStep2State,
} from 'App/Features/Campaign';
import StepOverview from 'Components/Page/Campaign/RegisterCampaign/Step1/StepOverview';
import StepSignUpRewards from 'Components/Page/Campaign/RegisterCampaign/Step2/StepSignUpRewards';
// import StepWinningConditions from 'Components/Page/Campaign/RegisterCampaign/Step3/StepWinningConditions'; // comment for hide blacklist
import StepConfirm from 'Components/Page/Campaign/RegisterCampaign/Step4/StepConfirm';
import { withRouter, RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import { CampaignApis } from 'Datasource/Campaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';

const RegisterCampaign: React.FunctionComponent<RouteComponentProps> = ({
  history,
}): JSX.Element => {
  const { step } = useAppSelector((state) => state.campaign);
  const dispatch = useAppDispatch();
  const { id }: { id: string } = useParams();
  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const location = useLocation<any>();

  const callbackRef = useRef<{ handleNextStepFromParent: () => void }>(null);
  const handleNextStep = (newStep: CampaignSteps) => {
    if (newStep < step) {
      dispatch(changeStep({ step: newStep }));
    }
    if (newStep > step) {
      callbackRef && callbackRef?.current?.handleNextStepFromParent();
    }
  };

  useEffect(
    () => {
      history?.listen(() => {
        dispatch(resetData());
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (location.pathname !== '/campaigns/register') {
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleGetCampaign = async () => {
    setLoading(true);
    try {
      const { data } = await CampaignApis.getDetail({ id });
      const overview = {
        name: data?.name,
        startDate: data?.startDate,
        endDate: data?.endDate,
        description: data?.description,
        status: data?.status,
        limitUser: data?.limitUser,
      };
      dispatch(
        updateStep1State({
          overview,
        }),
      );

      const prizes = data.prizes;
      dispatch(
        updateStep2State({
          prizes,
        }),
      );
      const { state } = location;

      dispatch(changeStep({ step: state?.isSaveDraft || CampaignSteps.Confirm }));
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <StepRegister handleNextStep={handleNextStep} />
      {step === CampaignSteps.Overview && (
        <StepOverview
          ref={callbackRef}
          campaignId={id}
          isValidate={isValidate}
          clearBackValidate={() => setIsValidate(false)}
        />
      )}
      {step === CampaignSteps.SignUpRewards && (
        <StepSignUpRewards
          ref={callbackRef}
          campaignId={id}
          setBackValidate={() => setIsValidate(true)}
        />
      )}
      {/* {step === CampaignSteps.WinningConditions && ( // comment for hide blacklist
        <StepWinningConditions
          ref={callbackRef}
          campaignId={id}
          handleValidateDraft={handleValidateDraft}
          setBackValidate={() => setIsValidate(true)}
        />
      )} */}
      {step === CampaignSteps.Confirm && (
        <StepConfirm campaignId={id} setBackValidate={() => setIsValidate(true)} />
      )}
    </>
  );
};

export default withRouter(RegisterCampaign);
