import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PopupProps {
  show: boolean;
  isSaymee?: string;
  prizePick?: any;
  handleVisible: () => void;
}
export default function SpinResultPopup({
  show,
  isSaymee,
  prizePick,
  handleVisible,
}: PopupProps): JSX.Element {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => handleVisible()}
        dialogClassName="modal-90w modal-spin-result"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        {prizePick ? (
          <>
            <Modal.Header className="justify-content-center">
              <Modal.Title className="text-truncate d-flex justify-content-center m-auto">
                <p className="title-header mb-0 text-header">Bạn đã trúng thưởng</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center spin-result text-center">
                <div>
                  <p className="text-strong">01 {prizePick.prizeName}</p>
                  <p>TRỊ GIÁ: {prizePick.price}</p>
                  <div className="mt-2">
                    <p>
                      Bạn sẽ nhận được thông báo trúng giải và hướng dẫn nhận thưởng từ MobiFone tới
                      số điện thoại đã đăng nhập trong vòng 7 ngày kể từ ngày quay số trúng thưởng.
                    </p>
                    <p>Xin chúc mừng!!!</p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-center">
                  <Button
                    variant="danger fs-14 rounded-pill text-bold"
                    onClick={() => handleVisible()}
                  >
                    Đóng lại
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </>
        ) : (
          <Modal.Body>
            <div className="wrapper-fail">
              <div className="d-flex justify-content-center spin-result text-center">
                <div>
                  <div className="mt-3">
                    {!isSaymee ? (
                      <>
                        <p>Chúc bạn may mắn lần sau.</p>
                        <p>Cảm ơn bạn đã tham gia!</p>
                      </>
                    ) : (
                      <p>Cảm ơn Bồ đã tham gia. Hãy tiếp tục ủng hộ Saymee nhé!!!!</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <Button
                    variant="danger fs-18 rounded-10 text-bold"
                    onClick={() => handleVisible()}
                  >
                    Đóng lại
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}
