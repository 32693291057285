import React from 'react';
import IconIos from 'Static/Icons/LandingPage/icon-ios.png';
import IconAndroid from 'Static/Icons/LandingPage/icon-android.png';
import IconHuawei from 'Static/Icons/LandingPage/icon-huawei.png';
import IconFacebook from 'Static/Icons/LandingPage/icon-facebook.png';
import IconYoutube from 'Static/Icons/LandingPage/icon-youtube.png';
import IconNews from 'Static/Icons/LandingPage/icon-news.png';
import IconZalo from 'Static/Icons/LandingPage/icon-zalo.png';
import IconTiktok from 'Static/Icons/LandingPage/icon-tiktok.png';
import { Image } from 'react-bootstrap';

export default function FooterMB(): JSX.Element {
  return (
    <>
      <footer className="footer-mbf">
        <div className="desktop">
          <div className="d-flex mx-5 py-3 top">
            <div className="col-sm-4">
              <p className="text-strong">TẢI ỨNG DỤNG MY MOBIFONE</p>
              <div className="d-flex mb-3 mt-3">
                <a href="https://apps.apple.com/vn/app/my-mobifone/id719320091">
                  <Image src={IconIos} className="w-60" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone">
                  <Image src={IconAndroid} className="w-60" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone">
                  <Image src={IconHuawei} className="icon-footer" />
                </a>
              </div>
              <p>KẾT NỐI VỚI MOBIFONE</p>
              <div className="d-flex mb-3 mt-3">
                <a href="https://apps.apple.com/vn/app/my-mobifone/id719320091">
                  <Image src={IconFacebook} className="w-60" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone">
                  <Image src={IconYoutube} className="w-60" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone">
                  <Image src={IconNews} className="w-60" />
                </a>
                <a href="https://www.tiktok.com/@mobifone.official" className="pe-4">
                  <Image src={IconTiktok} className="icon-footer" />
                </a>
                <a href="https://zalo.me/4055244641440308778">
                  <Image src={IconZalo} className="icon-footer" />
                </a>
              </div>
            </div>
            <div className="col-sm-3 d-flex flex-column">
              <p className="text-strong mb-1">GIỚI THIỆU</p>
              <a
                href="https://www.mobifone.vn/gioi-thieu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Giới thiệu MobiFone</span>
              </a>
              <a
                href="https://www.mobifone.vn/gioi-thieu/hop-tac-voi-mobifone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Hợp tác MobiFone</span>
              </a>
              <a
                href="https://www.mobifone.vn/tuyen-dung"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Tuyển dụng</span>
              </a>
              <a
                href="https://www.mobifone.vn/assets/source/file/logofontchuMobiFone.rar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Tải Logo</span>
              </a>
            </div>
            <div className="col-sm-5 d-flex">
              <div className="d-flex col-sm-6 flex-column">
                <p className="text-strong mb-1">HỖ TRỢ KHÁCH HÀNG</p>
                <a
                  href="https://www.mobifone.vn/tai-khoan/dang-nhap-nhanh?referal=https%3A%2F%2Fwww.mobifone.vn%2Fho-tro-khach-hang%2Fphan-anh"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Gửi phản ánh</span>
                </a>
                <a
                  href="https://www.mobifone.vn/ho-tro-khach-hang/cau-hoi-thuong-gap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Câu hỏi thường gặp</span>
                </a>
                <a
                  href="https://www.mobifone.vn/ho-tro-khach-hang/vi-tri-cua-hang"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Tìm kiếm cửa hàng</span>
                </a>
                <a href="https://chuyenmang.mobifone.vn/" target="_blank" rel="noopener noreferrer">
                  <span>Chuyển mạng giữ số</span>
                </a>
                <a
                  href="https://www.mobifone.vn/site-map"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Sitemap</span>
                </a>
                <a
                  href="https://www.mobifone.vn/tai-khoan/kiem-tra-dang-ky-thong-tin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Đăng ký thông tin</span>
                </a>
              </div>
              <div className="d-flex flex-column">
                <p className="text-strong mb-1">ĐIỀU KHOẢN & BẢO MẬT</p>
                <a
                  href="https://www.mobifone.vn/dieu-khoan-su-dung"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Điều khoản sử dụng</span>
                </a>
                <a
                  href="https://www.mobifone.vn/bao-mat-thong-tin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Bảo mật thông tin</span>
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex mx-5 py-3">
            <div className="col-sm-4">
              <div className="d-flex mb-2">
                <span className="icon-mark-ft"></span>
                <span>
                  Tổng công ty Viễn Thông MobiFone <br />
                  Số 01 phố Phạm Văn Bạch, Yên Hòa, Cầu Giấy, Hà Nội.
                </span>
              </div>
              <div className="d-flex mb-2">
                <span className="icon-phone-ft"></span>
                <span>(+84-24) 3783 1800</span>
              </div>
              <div className="d-flex">
                <span className="icon-scanner-ft"></span>
                <span>(+84-24) 3783 1734</span>
              </div>
            </div>
            <div className="col-sm-3">
              <p>
                <span className="icon-phone-hot-ft"></span>
                <span className="call">Tổng đài</span>
              </p>
              <p className="mb-1">9090 (200đ/phút)</p>
              <p>18001090 (miễn phí)</p>
            </div>
            <div className="col-sm-5">
              Giấy chứng nhận đăng ký doanh nghiệp: Mã số doanh nghiệp: 0100686209, Đăng ký thay đổi
              lần thứ 10 ngày 10/03/2021, cấp bởi sở KHĐT Thành phố Hà Nội.
            </div>
          </div>
        </div>
        <div className="mobile py-4 px-4">
          <div className="col-xs-12 top">
            <p className="text-strong">TẢI ỨNG DỤNG MY MOBIFONE</p>
            <div className="d-flex mb-3 mt-3">
              <a href="https://apps.apple.com/vn/app/my-mobifone/id719320091">
                <Image src={IconIos} className="w-70" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone"
                className="px-4"
              >
                <Image src={IconAndroid} className="w-70" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone">
                <Image src={IconHuawei} className="icon-footer" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 py-4 top">
            <p>KẾT NỐI VỚI MOBIFONE</p>
            <div className="d-flex mt-3">
              <a href="https://apps.apple.com/vn/app/my-mobifone/id719320091">
                <Image src={IconFacebook} className="w-60" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone"
                className="ps-4 pe-3"
              >
                <Image src={IconYoutube} className="w-60" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=vms.com.vn.mymobifone"
                className="pe-2"
              >
                <Image src={IconNews} className="w-60" />
              </a>
              <a href="https://www.tiktok.com/@mobifone.official" className="pe-4">
                <Image src={IconTiktok} className="icon-footer" />
              </a>
              <a href="https://zalo.me/4055244641440308778">
                <Image src={IconZalo} className="icon-footer" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 py-4">
            <p className="pb-3">TỔNG ĐÀI</p>
            <p>
              <span className="icon-phone-hot-ft"></span>
              <span className="call">9090 (200đ/phút) | 18001090 (miễn phí)</span>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
