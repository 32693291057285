import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailValidation, stringRequiredTrim } from 'Utils/Validation';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import { NOTE_UPDATE_PASSWORD, RoleOptionsType, SelectOption } from 'Constant/Manger';
import { useHistory, useParams } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ManagerApis } from 'Datasource/Manager';
import useMessage from 'Hooks/useMessage';
import { find, omitBy } from 'lodash';

export interface FormManager {
  isUpdate: boolean;
  name: string;
  email: string;
  phone: string;
  roleCd?: SelectOption;
  password?: string;
  confirmPassword?: string;
}

const schema = yup.object().shape({
  isUpdate: yup.boolean(),
  name: stringRequiredTrim({}),
  email: emailValidation({}),
  phone: stringRequiredTrim({}),
  password: yup
    .string()
    .when('isUpdate', {
      is: false,
      then: stringRequiredTrim({}),
    })
    .nullable(),
  confirmPassword: yup
    .string()
    .test({
      message: 'Mật khẩu không khớp',
      test: function (value) {
        return value === this.parent.password;
      },
    })
    .nullable(),
});

export default function CreateManager(): JSX.Element {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [dataManager, setDataManager] = useState<FormManager>({
    isUpdate: !!id,
    name: '',
    email: '',
    phone: '',
    roleCd: find(RoleOptionsType, { value: 'MANAGER' }),
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormManager>({
    defaultValues: dataManager,
    resolver: yupResolver(schema),
  });
  const { openMessageError, openMessage } = useMessage();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCreateOrUpdate = async (values: any) => {
    setIsLoading(true);
    try {
      if (id) {
        let params = { ...values, roleCd: values.roleCd.value };
        params = omitBy(params, (v) => !v);
        await ManagerApis.update({ id: id, params: params });
        openMessage({
          variant: 'success',
          message: 'Cập nhật thành công',
        });
      } else {
        await ManagerApis.create({ params: { ...values, roleCd: values.roleCd.value } });
        openMessage({
          variant: 'success',
          message: 'Đăng ký thành công',
        });
      }
      history.push('/admin/managers');
    } catch (error) {
      console.log(error);
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const { data } = await ManagerApis.getDetail({ id: id });
      setDataManager(data);
      reset({
        isUpdate: true,
        email: data.email,
        name: data.name,
        phone: data.phone,
        roleCd: find(RoleOptionsType, { value: data.roleCd }),
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container className="mt-4">
      <SpinnerComponent isLoading={isLoading} />
      <Row>
        <Col md="8" className="d-flex justify-content-start ps-0 pb-4">
          <Button
            onClick={() => history.push(`/admin/managers`)}
            className="btn-focus-none px-3 button-submit"
          >
            Danh sách người quản lý
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="px-0">
          <BoxForm title="Tạo tài khoản quản lý">
            <>
              <Form
                onSubmit={handleSubmit(handleCreateOrUpdate)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                id="create-manager"
              >
                <FormGroupInput
                  value={watch('name')}
                  labelMd="2"
                  colMd="10"
                  label="Tên:"
                  register={{ ...register('name') }}
                  onChange={(value) => setValue('name', value)}
                  errorMessage={errors.name?.message}
                />
                <FormGroupInput
                  value={watch('email')}
                  labelMd="2"
                  colMd="10"
                  label="Email:"
                  onChange={(value) => setValue('email', value)}
                  register={{ ...register('email') }}
                  errorMessage={errors.email?.message}
                />
                <FormGroupInput
                  value={watch('phone')}
                  labelMd="2"
                  colMd="10"
                  label="Số điện thoại:"
                  onChange={(value) => setValue('phone', value)}
                  register={{
                    ...register('phone'),
                  }}
                  errorMessage={errors.phone?.message}
                  maxLength={13}
                />
                <FormGroupSelect
                  rowClass="mb-3"
                  label="Vai trò:"
                  labelMd="2"
                  colMd="4"
                  value={watch('roleCd')}
                  options={RoleOptionsType}
                  placeholder="Vui lòng chọn một giá trị"
                  onChange={(data) => {
                    setValue('roleCd', data);
                  }}
                />
                <FormGroupInput
                  value={watch('password')}
                  labelMd="2"
                  colMd="10"
                  label="Mật khẩu:"
                  type="password"
                  onChange={(value) => setValue('password', value)}
                  register={{
                    ...register('password'),
                  }}
                  errorMessage={errors.password?.message}
                  hintText={id ? NOTE_UPDATE_PASSWORD : ''}
                />
                <FormGroupInput
                  value={watch('confirmPassword')}
                  labelMd="2"
                  colMd="10"
                  label="Xác nhận mật khẩu"
                  type="password"
                  onChange={(value) => setValue('confirmPassword', value)}
                  register={{
                    ...register('confirmPassword'),
                  }}
                  errorMessage={errors.confirmPassword?.message}
                />
                <div className="d-flex justify-content-center pb-4">
                  <Button
                    className="mt-4 mb-4 btn-focus-none btn-curator-register"
                    variant="primary"
                    form="create-manager"
                    onClick={() => handleSubmit(handleCreateOrUpdate)()}
                  >
                    Lưu lại
                  </Button>
                </div>
              </Form>
            </>
          </BoxForm>
        </Col>
      </Row>
    </Container>
  );
}
