import CardWrapper from 'Components/Common/Card/Card';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updateStep1State } from 'App/Features/Campaign';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { normalizeDate } from 'Utils/DateTime';
import { DateType } from 'Types/Page/Campaign/RegisterCampaign';
import CpFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/CpFormGroupDateRange';
import { numberRequired, stringRequired } from 'Utils/Validation';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { limitUserSetting } from 'Constant/Campaign';

interface IFormValues {
  name: string;
  startDate: DateType;
  endDate: DateType;
  description: string;
  status: number;
  isDeletable: boolean;
  limitUser: number;
}

export const overviewSchema = yup.object().shape({
  name: stringRequired({}).trim().nullable(),
  startDate: stringRequired({}).nullable(),
  endDate: stringRequired({}).nullable(),
  limitUser: numberRequired({}).nullable(),
});

const StepOverview = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { overview, step } = useAppSelector((state) => state.campaign);
  const { handleChangeStep, isLoading, handleDeleteCampaign } = useCampaign();
  const {
    register,
    getValues,
    trigger,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: {
      name: '',
      startDate: null,
      endDate: null,
      description: '',
      status: 0,
      isDeletable: true,
      limitUser: 0,
    },
    resolver: yupResolver(overviewSchema),
  });

  const handleChangeDate = (date: DateType, type: string) => {
    setValue(`${type}` as any, date);
  };

  const handleNext = async () => {
    const formData = getValues();
    dispatch(updateStep1State({ overview: formData }));
    handleChangeStep(step + 1);
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent() {
      handleNext();
    },
  }));

  useEffect(() => {
    reset({
      name: overview.name,
      startDate: overview.startDate,
      endDate: overview.endDate,
      description: overview.description,
      status: overview?.status,
      isDeletable: overview?.isDeletable,
      limitUser: overview?.limitUser,
    });
  }, [reset, overview]);

  const handleTrigger = async () => {
    await trigger();
    props?.clearBackValidate();
  };

  useEffect(() => {
    if (props?.isValidate) {
      handleTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isValidate]);

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <CardWrapper title="Tổng quan về chiến dịch">
        <FormGroupInput
          label="Tên chiến dịch mới"
          labelMd="2"
          colMd="10"
          value={watch('name')}
          register={register('name')}
          onChange={(value) => setValue('name', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors?.name?.message}
        />
        <CpFormGroupDateRange
          label="Thời gian diễn ra"
          labelMd="2"
          colMd="9"
          placeholderStart="Thời gian bắt đầu"
          placeholderEnd="Thời gian kết thúc"
          showTimeSelect
          startDate={normalizeDate(watch('startDate'))}
          endDate={normalizeDate(watch('endDate'))}
          onChangeStartDate={(date: any) => {
            handleChangeDate(date, 'startDate');
            if (!date) {
              handleChangeDate(null, 'endDate');
            }
          }}
          onChangeEndDate={(date: any) => {
            handleChangeDate(date, 'endDate');
          }}
          endDateDisabled={!watch('startDate')}
          minStartDate={new Date()}
          maxStartDate={normalizeDate(watch('endDate'))}
          errorStartDate={errors?.startDate?.message}
          errorEndDate={errors?.endDate?.message}
        />
        <FormGroupTextArea
          labelMd="2"
          colMd="10"
          label="Thể lệ chương trình"
          rows={4}
          register={register('description')}
          value={watch('description')}
          onChange={(value) => setValue('description', value)}
        />
        <CheckBoxs
          id="pick-type"
          labelForm="Đối tượng tham gia"
          name="limitUser"
          labelMd="2"
          colMd="10"
          classForm="form-with-label-nowrap mb-3"
          errorMessage={errors.limitUser?.message}
          options={limitUserSetting}
          value={watch(`limitUser`)}
          onChange={({ value }) => setValue('limitUser', value)}
          hintText="Đối tượng có thể tham gia chương trình"
          classOption="pe-4"
        />
      </CardWrapper>

      <ActionButtons
        handleDelete={() => handleDeleteCampaign(props?.campaignId)}
        handleNext={() => {
          handleNext();
        }}
      />
    </>
  );
});

export default StepOverview;
