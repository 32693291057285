/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image, Form } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import UserCampaignTable from 'Components/Page/Campaign/UserCampaignTable';
import { CampaignApis } from 'Datasource/Campaign';
import searchIcon from 'Static/Images/search-icon.svg';
import CpFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/CpFormGroupDateRange';
import { normalizeDate } from 'Utils/DateTime';
import { DateType } from 'Types/Page/Campaign/RegisterCampaign';

export default function UserCampaignList(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [usersCampaign, setUsersCampaign] = useState<any>([]);
  const { id } = useParams<{ id: string }>();
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    keyword: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    status: StringParam,
  });
  // const [page, setPage] = useState(query.page ?? 1);

  const history = useHistory();
  const { openMessageError } = useMessage();

  const handleChangeDate = (date: DateType, type: string) => {
    if (type == 'startDate') {
      setQuery({ startDate: date ? date.toISOString() : '' });
      return;
    }
    setQuery({ endDate: date ? date.toISOString() : '' });
  };

  const handleGetUserCampaign = async () => {
    try {
      setLoading(true);
      const { data } = await CampaignApis.getUsersCampaign({
        id,
        page: query.page ?? 1,
        status: query.status ?? '',
        keyword: query.keyword ?? '',
        startDate: query.startDate ?? '',
        endDate: query.endDate ?? '',
      });
      setTotal(data.totalCount);
      setUsersCampaign(data.users);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnchangePhone = (e: any) => {
    setQuery({ keyword: e.target.value });
  };

  const onChangePage = (page: number) => {
    setQuery({ page: page });
  };

  const search = () => {
    setQuery({ page: 1 });
    handleGetUserCampaign();
  };

  useEffect(() => {
    handleGetUserCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.page]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8" className="d-flex justify-content-start px-4 pt-4">
            <Button
              onClick={() => history.push(`/admin/campaigns`)}
              className="btn-focus-none px-3 button-submit"
            >
              Danh sách chiến dịch
            </Button>
          </Col>
        </Row>
        <div className="px-4">
          <SpinnerComponent isLoading={loading} />
          <Row className="my-3">
            <Col md={12} className="d-flex flex-row p-0">
              <Col md={3}>
                <div className="position-relative input-search-wrapper">
                  <Image src={searchIcon} className="icon-search position-absolute" />
                  <Form.Control
                    className="input-search pe-0"
                    value={query.keyword ?? ''}
                    onChange={handleOnchangePhone}
                    placeholder="Vui lòng nhập từ khóa (sđt, code,...)"
                    type="search"
                  />
                </div>
              </Col>
              <Col md={2} className="px-4">
                <Form.Select
                  aria-label="Default select example"
                  className="fs-14 input-search"
                  as="select"
                  value={query.status ?? ''}
                  onChange={(e: any) => {
                    setQuery({ status: e.target.value });
                  }}
                >
                  <option value="">Tất cả</option>
                  <option value="1">Trúng</option>
                  <option value="0">Trượt</option>
                  <option value="3">Chưa quay thưởng</option>
                </Form.Select>
              </Col>
              <Col md={5}>
                <CpFormGroupDateRange
                  colMd="12"
                  placeholderStart="Bắt đầu"
                  placeholderEnd="Kết thúc"
                  showTimeSelect
                  minStartDate={new Date('2023/08/30 00:00')}
                  startDate={normalizeDate(query.startDate ?? null)}
                  endDate={normalizeDate(query.endDate ?? null)}
                  onChangeStartDate={(date: any) => {
                    handleChangeDate(date, 'startDate');
                    if (!date) {
                      handleChangeDate(null, 'endDate');
                    }
                  }}
                  onChangeEndDate={(date: any) => {
                    handleChangeDate(date, 'endDate');
                  }}
                  endDateDisabled={!query.startDate}
                  maxStartDate={normalizeDate(query.endDate ?? null)}
                />
              </Col>
              <Col md={2} className="d-flex justify-content-center">
                <div>
                  <Button onClick={() => search()} className="btn-focus-none fs-14">
                    Tìm kiếm
                  </Button>
                </div>
              </Col>
            </Col>
            <Col md={12} className="d-flex justify-content-end p-0">
              <PaginationComponent
                onClick={onChangePage}
                activePage={query?.page ?? 1}
                total={total ?? 0}
                perPage={PER_PAGE}
              />
            </Col>
          </Row>
          <UserCampaignTable dataSource={usersCampaign || []} />
        </div>
      </Container>
    </>
  );
}
