import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import JSCookie from 'js-cookie';
import Page404 from 'Page/Exception/404';
import Layout from 'Components/Layouts/Layout';
import Login from 'Page/Login';
import ForgotPassword from 'Page/ForgotPassword';
import ResetPassword from 'Page/ResetPassword';
import ChangePassword from 'Page/ChangePassword';
import { ReactNode } from 'Components/Layouts/common';
import RegisterCampaign from 'Page/Campaign/Register';
import Manager from 'Page/Manager';
import CreateManager from 'Page/Manager/register';
import UserInfo from 'Page/UserInfo';
import CampaignList from 'Page/Campaign';
import UserCampaignList from 'Page/Campaign/user';
import VoucherList from 'Page/Vourcher';
import VoucherRegister from 'Page/Vourcher/register';
import { useAppSelector } from 'App/Store';
import LandingPage from 'Page/LandingPage';
interface RouteProps {
  component: ReactNode;
  auth: boolean;
  userRole?: Array<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, auth, ...rest }: RouteProps) => {
  return <Route {...rest} render={() => (auth ? <Component /> : <Redirect to="/admin/login" />)} />;
};

const PublicRoute = ({ component: Component, auth, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={() =>
        auth && rest.path == '/admin/login' ? <Redirect to="/admin/campaigns" /> : <Component />
      }
    />
  );
};

export default function Routing(): JSX.Element {
  const {
    auth: { email },
  } = useAppSelector((state) => state);
  const accessToken = JSCookie.get('token');
  const isAccept = !!accessToken && !!email;
  const isAcceptChangePassword = !!accessToken;

  return (
    <Switch>
      <PublicRoute auth={isAccept} exact strict path="/" component={LandingPage} />
      <PublicRoute auth={isAccept} exact strict path="/:personalId" component={LandingPage} />
      <PublicRoute auth={isAccept} exact strict path="/saymee" component={LandingPage} />
      <PublicRoute
        auth={isAccept}
        exact
        strict
        path="/saymee/:personalId"
        component={LandingPage}
      />
      <PublicRoute auth={isAccept} exact strict path="/play-game" component={LandingPage} />
      <PublicRoute auth={isAccept} exact strict path="/admin/login" component={Login} />
      <PublicRoute
        auth={isAccept}
        exact
        strict
        path="/admin/forgot-password"
        component={ForgotPassword}
      />
      <PublicRoute
        auth={isAccept}
        exact
        strict
        path="/admin/reset-password"
        component={ResetPassword}
      />
      <PrivateRoute
        auth={isAcceptChangePassword}
        exact
        strict
        path="admin/change-password"
        component={ChangePassword}
      />
      <Layout>
        <Switch>
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/campaigns"
            component={CampaignList}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/campaigns/register"
            component={RegisterCampaign}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/campaigns/:id"
            component={RegisterCampaign}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/campaigns/:id/users"
            component={UserCampaignList}
          />
          <PrivateRoute auth={isAccept} exact strict path="/admin/managers" component={Manager} />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/managers/register"
            component={CreateManager}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/managers/:id"
            component={CreateManager}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/user-information"
            component={UserInfo}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/vouchers"
            component={VoucherList}
          />
          <PrivateRoute
            auth={isAccept}
            exact
            strict
            path="/admin/vouchers/register"
            component={VoucherRegister}
          />
          <PrivateRoute auth={isAccept} exact strict component={Page404} />
        </Switch>
      </Layout>
      <Route component={Page404} />
    </Switch>
  );
}
