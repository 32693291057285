/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { useQueryParams, NumberParam } from 'use-query-params';
import ManagerTable from 'Components/Page/Manager/ManagerTable';
import { ManagerApis } from 'Datasource/Manager';

export default function Manager(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [managers, setManagers] = useState<any>([]);
  const history = useHistory();
  const { openMessageError } = useMessage();
  const [query] = useQueryParams({
    page: NumberParam,
  });
  const [page, setPage] = useState(query.page ?? 1);
  const [total, setTotal] = useState(0);
  const handleGetManger = async () => {
    try {
      setLoading(true);
      const { data } = await ManagerApis.getAll({
        page,
      });
      setTotal(data.totalCount);
      setManagers(data.admins);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    handleGetManger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.page]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8" className="d-flex justify-content-start px-4 pt-4">
            <Button
              onClick={() => history.push(`/admin/managers/register`)}
              className="btn-focus-none px-3 button-submit"
            >
              Tạo tài khoản quản lý
            </Button>
          </Col>
        </Row>
        <div className="px-4">
          <SpinnerComponent isLoading={loading} />
          <Row className="my-3">
            <Col md={12} className="d-flex justify-content-end p-0">
              <PaginationComponent
                onClick={onChangePage}
                activePage={page ?? 1}
                total={total ?? 0}
                perPage={PER_PAGE}
              />
            </Col>
          </Row>
          <ManagerTable dataSource={managers || []} />
        </div>
      </Container>
    </>
  );
}
