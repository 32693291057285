/* eslint-disable react/no-unescaped-entities */
import ErrorPopup from 'Components/Common/LandingPagePopup/ErrorPopup';
import SpinResultPopup from 'Components/Common/LandingPagePopup/SpinResultPopup';
import { LandingPageApis } from 'Datasource/LandingPage';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface ParamsSpin {
  code: string;
  isSaymee: string;
  campaignId: number;
}

interface WheelProps {
  isDisable: boolean;
  paramsSpin: ParamsSpin;
  handleDisable: () => void;
}

const LuckyWheel = ({ isDisable, paramsSpin, handleDisable }: WheelProps): JSX.Element => {
  const [webkitTransform, setWebkitTransform] = useState<string>('');
  const [showSpinResultModal, setShowSpinResultModal] = useState(false);
  const [prizePick, setPrizePick] = useState<any>(undefined);
  const [messageError, setMessageError] = useState<string[]>([]);
  const [isShowError, setIsShowError] = useState(false);

  const prizeSetting = [
    {
      prizeName: 'Điện thoại iPhone 15 Pro (256GB)',
      price: '29.700.000 VND',
      wheelPosition: [0, 8],
    },
    {
      prizeName: 'Tai nghe AirPods 3 với hộp sạc lightning',
      price: '3.960.000 VND',
      wheelPosition: [2, 10],
    },
    {
      prizeName: 'Đồng hồ thông minh Apple watch SE 2023 40mm',
      price: '7.150.000 VNĐ',
      wheelPosition: [4, 12],
    },
  ];

  let maxPos = 0;
  const rollToCalculator = (indexOfItem: number) => {
    const prizes = 12;
    const _degree = indexOfItem * (360 / prizes) + 360 * 12 + maxPos;
    maxPos = _degree - indexOfItem * (360 / prizes);

    return `translate3d(0, 0, 0) rotateZ(${-1 * _degree}deg)`;
  };

  const play = async () => {
    if (isDisable) return;
    handleDisable();
    let positionWheel = 1;
    try {
      const { data } = await LandingPageApis.spinRealtime(paramsSpin);
      if (data.positionWheel !== null) positionWheel = data.positionWheel;
    } catch (error) {
      const message = [] as string[];
      const { response } = error as AxiosError;
      response?.data?.message?.map((mes: string) => {
        message.push(mes);
      });
      setMessageError(message);
      setIsShowError(true);

      return;
    }

    prizeSetting.map((p) => {
      const position = p.wheelPosition.find((p) => p === positionWheel);
      if (position == 0 || position) {
        setPrizePick(p);
      }
    });
    const transform = rollToCalculator(positionWheel);
    setWebkitTransform(transform);

    await new Promise((resolve) => {
      setTimeout(resolve, 3500);
    });
    setShowSpinResultModal(true);
  };

  return (
    <>
      <div className="game-box">
        <span className="arrow"></span>
        <canvas
          style={{ WebkitTransform: webkitTransform }}
          width="548"
          height="548"
          data-responsiveminwidth="180"
          data-responsivescaleheight="true"
        >
          <p>Sorry, your browser doesn't support canvas. Please try another.</p>
        </canvas>
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={() => play()} className="btn-spin">
            QUAY THƯỞNG
          </Button>
        </div>
      </div>
      <SpinResultPopup
        show={showSpinResultModal}
        isSaymee={paramsSpin.isSaymee}
        prizePick={prizePick}
        handleVisible={() => setShowSpinResultModal(false)}
      />
      <ErrorPopup
        show={isShowError}
        message={messageError}
        handleVisible={() => setIsShowError(false)}
      />
    </>
  );
};

export default LuckyWheel;
