import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListGroup, Accordion } from 'react-bootstrap';
import * as H from 'history';
import CustomToggle from 'Components/Layouts/ToggleButton';
import { NavigationProps, Match } from 'Components/Layouts/common';
import { handleCheckDynamicRoutes } from 'Utils/Route';

interface childItem {
  title: string;
  slug: string;
}

const Navigation = ({ menu }: NavigationProps): JSX.Element => {
  const handleCheckMatchLink = (
    match: Match | null,
    location: H.Location,
    childItem: childItem,
  ) => {
    let active = false;
    if (!match) {
      active = false;
    }
    if (match?.url === location.pathname) {
      active = true;
    }
    const childrenRoute = handleCheckDynamicRoutes(location);
    if (childItem?.slug && childItem?.slug === childrenRoute?.parent) {
      active = true;
    }

    return active;
  };

  return (
    <div className="br-nav d-flex flex-column align-items-center align-items-sm-start text-white br-custom-scrollbar">
      <div className="w-100 vh-layout overflow-auto br-custom-scrollbar bg-black">
        {menu.map((item, key) => {
          if (item.title == 'Vouchers') return; // Waiting release phase 2
          return (
            <Accordion defaultActiveKey={`${key}`} key={key}>
              <ListGroup className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
                <ListGroup.Item className="w-100">
                  <CustomToggle eventKey={`${key}`} eventItem={item} />
                  <Accordion.Collapse eventKey={`${key}`}>
                    <ListGroup className="collapse show nav flex-column">
                      {item.children.map((childItem, childKey) => {
                        return (
                          <ListGroup.Item key={childKey} className="rounded-0">
                            <NavLink
                              activeClassName="active"
                              isActive={(match, location) =>
                                handleCheckMatchLink(match, location, childItem)
                              }
                              to={childItem.slug}
                              className="nav-link p-0 menu-children rounded-0"
                            >
                              <span className="d-none d-sm-block ms-4 px-2 py-1 border-bottom-1">
                                {childItem.title}
                              </span>
                            </NavLink>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Accordion.Collapse>
                </ListGroup.Item>
              </ListGroup>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
