import { stringRequired, numberRequired } from 'Utils/Validation';
import * as yup from 'yup';

// refactor schema for validate here
const schema = yup.object().shape({
  name: stringRequired({}).trim().nullable(),
  type: numberRequired({}).nullable(),
  positionWheel: numberRequired({}).nullable(),
});

export default schema;
