import { find } from 'lodash';
import { matchPath } from 'react-router-dom';
import * as H from 'history';

interface RouteInfo {
  name: string;
  title: string;
  parent: string;
}

const dynamicRoutes = [
  { name: 'admin/campaigns/:id', title: 'Chỉnh sửa chiến dịch', parent: 'admin/campaigns' },
];

export const handleCheckDynamicRoutes = (location: H.Location): RouteInfo | undefined => {
  const matchRoute = find(dynamicRoutes, (rt) => {
    const match = matchPath(location.pathname, {
      path: rt.name,
      exact: true,
      strict: true,
    });
    return !!match;
  });
  return matchRoute;
};
