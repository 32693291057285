/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { prizeOptionsType } from 'Constant/Campaign';
import { find, isEmpty } from 'lodash';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';
import TablePrizeRow from './TablePrizeRow';

interface TableProps {
  prizes: Prize[];
}

const PrizesTable = ({ prizes }: TableProps): JSX.Element => {
  const [items, setItems] = useState(prizes);

  useEffect(() => {
    setItems(prizes);
  }, [prizes]);
  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-40">Tên giải thưởng</th>
            <th className="w-10">Loại</th>
            <th className="w-10">Vị trí</th>
            <th className="w-40">Cài đặt thời gian trúng giải</th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(items) ? (
            <tr>
              <td className="text-center align-middle" colSpan={4}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            items.map((value, key) => {
              const awardType = find(prizeOptionsType, (option) => option.value === value.type);
              return (
                <TablePrizeRow
                  key={`item-${key}`}
                  name={value?.name}
                  type={awardType?.label}
                  settingProbabilities={value.settingProbabilities}
                  positionWheel={value?.positionWheel}
                />
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(PrizesTable);
