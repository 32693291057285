import React, { useEffect } from 'react';
import { ErrorApi } from 'Datasource/Error';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import SVG404 from 'Static/Images/404.png';

export default function Page404(): JSX.Element {
  const test = async () => {
    const res = await ErrorApi.getError();
    return res;
  };

  useEffect(() => {
    test();
  }, []);

  return (
    <Container className="page-404 vh-layout">
      <Row>
        <Col sm={12}>
          <Col sm={12} className="justify-content-sm-center text-center">
            <img className="w-max-content" src={SVG404} alt="404 icon" />
            <div className="mt-4">
              <h3 className="h2 fs-3">Có vẻ như bạn bị lạc</h3>
              <p className="fs-6">Trang bạn đang tìm kiếm không tồn tại!</p>
              <Link to="/" className="link-404 px-5 py-2 mt-4 d-inline-block">
                Trở về trang chủ
              </Link>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
