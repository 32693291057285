/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import Auth from 'App/Features/Auth';
import PullDown from 'App/Features/PullDown';
import Campaign from 'App/Features/Campaign';
import Layout from 'App/Features/Layout';
import Voucher from './Features/Voucher';

const reducers = combineReducers({
  auth: Auth,
  pullDown: PullDown,
  campaign: Campaign,
  voucher: Voucher,
  layout: Layout,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['title'],
  // blacklist: ['title', 'campaign'],
  // whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
