import { useState } from 'react';
import useMessage from 'Hooks/useMessage';
import { CampaignApis } from 'Datasource/Campaign';
import { useAppDispatch } from 'App/Store';
import { CampaignSteps, changeStep } from 'App/Features/Campaign';
import { useHistory } from 'react-router-dom';

const useCampaignDraft = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { openMessageError, openMessage } = useMessage();
  const dispatch = useAppDispatch();

  const handleChangeStep = (newStep: CampaignSteps) => {
    dispatch(changeStep({ step: newStep }));
  };

  const saveOrUpdateOfficial = (dataInput: any) => {
    const prizes = dataInput.prizes;
    const params = {
      prizes,
      ...dataInput?.overview,
    };
    (async function fetchData() {
      try {
        if (!dataInput?.id) {
          await CampaignApis.create({
            params,
          });
          openMessage({
            variant: 'success',
            message: 'Đăng ký thành công.',
          });
          history.push(`/admin/campaigns`);
        } else {
          const id = dataInput.id;
          await CampaignApis.update({
            id,
            params,
          });
          openMessage({
            variant: 'success',
            message: 'Cập nhật thành công',
          });
          history.push(`/admin/campaigns`);
        }
        setIsSuccess(true);
      } catch (error) {
        openMessageError(error);
        setIsSuccess(false);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const handleDeleteCampaign = async (id: string | number) => {
    try {
      await CampaignApis.deleteCampaign({ id });
      history.push('/admin/campaigns');
    } catch (error) {
      openMessageError(error);
    }
  };

  return {
    isSuccess,
    isLoading,
    saveOrUpdateOfficial,
    handleChangeStep,
    handleDeleteCampaign,
  };
};

export default useCampaignDraft;
