import { MessageProps } from 'Components/Common/Message';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useMessage(): any {
  const openMessage = useCallback(
    (data: MessageProps) => toast(data.message, { type: data.variant }),
    [],
  );
  const openMessageError = useCallback((data) => {
    const { response } = data as AxiosError;
    response?.data?.message?.map((mes: string) => {
      toast(mes, { type: 'error' });
    });
  }, []);
  return { openMessage, openMessageError };
}
