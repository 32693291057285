import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Routing from 'Router';
import Message from 'Components/Common/Message';

function App(): JSX.Element {
  return (
    <>
      <Message />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Routing />
        </QueryParamProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
