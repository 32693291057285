import React from 'react';

function HintTextField({ hintText }: { hintText: string }): JSX.Element {
  return (
    <>
      {hintText && (
        <p style={{ whiteSpace: 'pre-line' }} className="fs-12 px-0">
          {hintText}
        </p>
      )}
    </>
  );
}

export default HintTextField;
