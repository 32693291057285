import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { pullAt } from 'lodash';
import { CAMPAIGN_INITIAL_STATE } from 'Constant/Campaign';

export enum CampaignSteps {
  Overview = 0,
  SignUpRewards = 1,
  // WinningConditions = 2,
  Confirm = 2,
  Success = 3, // comment for hide blacklist
}

const initialState: any = { ...CAMPAIGN_INITIAL_STATE };

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    changeStep: (state, data: PayloadAction<{ step: CampaignSteps }>) => {
      const { step } = data.payload;
      state.step = step;
    },
    updateStep1State: (state, data: PayloadAction<any>) => {
      const { overview } = data.payload;
      state.overview = overview;
    },
    updateStep2State: (state, data: PayloadAction<any>) => {
      const { prizes } = data.payload;
      prizes.map((p: any, i: number) => {
        p.settingProbabilities.map((s: any, index: number) => {
          return (s.index = index);
        });
        return (p.index = i);
      });

      state.prizes = prizes;
    },
    updatePrizes: (state, data: PayloadAction<any>) => {
      const { prize } = data.payload;
      let prizes = current(state.prizes);
      // case new prize
      if (prize.index == undefined) {
        prize.index = prizes.length ? prizes[prizes.length - 1].index + 1 : 0;
        prizes = [...prizes, prize];
      } else {
        // case existed prize (update)
        prizes = prizes.map((p: any) => {
          return prize.index == p.index ? prize : p;
        });
      }
      state.prizes = prizes;
    },
    deletePrize: (state, data: PayloadAction<any>) => {
      const { prize } = data.payload;
      const prizes = state.prizes;
      if (prize.index !== undefined) {
        pullAt(prizes, [prize.index]);
      }

      state.prizes = prizes;
    },
    updateBlacklists: (state, data: PayloadAction<any>) => {
      const blacklist = data.payload;
      let blacklists = current(state.blacklists);
      blacklist.index = blacklists.length ? blacklists.length + 1 : 0;
      blacklists = [...blacklists, blacklist];
      state.blacklists = blacklists;
    },
    deleteBlacklist: (state, data: PayloadAction<any>) => {
      const blacklist = data.payload;
      const blacklists = state.blacklists;
      if (blacklist.index !== undefined) {
        pullAt(blacklists, [blacklist.index]);
      }
      state.blacklists = blacklists;
    },
    updateWhitelists: (state, data: PayloadAction<any>) => {
      const whitelist = data.payload;
      let whitelists = current(state.whitelists);
      whitelist.index = whitelists.length ? whitelists.length + 1 : 0;
      whitelists = [...whitelists, whitelist];
      state.whitelists = whitelists;
    },
    deleteWhitelist: (state, data: PayloadAction<any>) => {
      const whitelist = data.payload;
      const whitelists = state.whitelists;
      if (whitelist.index !== undefined) {
        pullAt(whitelists, [whitelist.index]);
      }

      state.whitelists = whitelists;
    },
    resetData: (state) => {
      state = { ...CAMPAIGN_INITIAL_STATE };
      return state;
    },
  },
});

export const {
  changeStep,
  updatePrizes,
  updateStep1State,
  updateStep2State,
  deletePrize,
  updateBlacklists,
  deleteBlacklist,
  resetData,
  updateWhitelists,
  deleteWhitelist,
} = campaignSlice.actions;
export default campaignSlice.reducer;
