import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PullDownState {
  filterByText: boolean;
}

const initialState: PullDownState = {
  filterByText: false,
};

export const pullDownSlice = createSlice({
  name: 'pulldown',
  initialState,
  reducers: {
    setPullDownSearchbyText: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.filterByText = payload;
    },
  },
});

export const { setPullDownSearchbyText } = pullDownSlice.actions;
export default pullDownSlice.reducer;
