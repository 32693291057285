export const PAGE_SIZE = 20;
export const PAGE_SIZE_ARTIST = 15;
export const PAGE_SIZE_APPROVAL = 10;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
export const NUMBER_ONLY_REGEX = /^\d+$/;
export const NUMBER_REGEX = /\d/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const WHITESPACE = /^\S*$/;

export const TYPE_MEDIA = {
  VARIATIONS: 0,
  SONGS: 1,
  DIGITAL_TOKUTEN: 2,
  CAMPAIGN: 3,
};

export const TOKUTEN_TYPE_MEDIA = {
  IMAGE: 0,
  VIDEO: 1,
  AUDIO: 2,
};

export const PER_PAGE = 20;
export const MAX_INPUT = 255;
export const REGEX_PHONE_VN =
  /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/gm;
