/* eslint-disable no-useless-escape */
/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import * as Yup from 'yup';
import { string } from 'yup';
// Messages definition
export const minMsg = (min: number) => `Vui lòng nhập ít nhất ${min} ký tự`;
export const maxMsg = (max: number) => `Vui lòng nhập nhỏ hơn ${max} ký tự`;
// regex
export const MailRegExp = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const regexValidatePassword = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/;
export const PERCENTAGE_REGEX = /^(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/;

export const stringRequiredTrim = ({ required = 'Vui lòng nhập.' }) =>
  Yup.string().required(required).trim(required);
export const percentageValidation = ({ required = 'Vui lòng nhập.' }) =>
  Yup.string()
    .required(required)
    .matches(PERCENTAGE_REGEX, 'Vui lòng nhập đúng giá trị phần trăm.');
export const stringRequired = ({ required = 'Vui lòng nhập.' }) => Yup.string().required(required);
export const numberRequired = ({ required = 'Vui lòng nhập.' }) => Yup.number().required(required);
export const stringNotRequired = () => Yup.string();
export const maxLengthInput = (maxLength: number) =>
  stringNotRequired().max(maxLength, maxMsg(maxLength));
export const emailValidation = ({
  email = 'Định dạng email không hợp lệ.',
  required = 'Vui lòng nhập.',
}) => stringRequired({ required }).email(email).max(100, maxMsg(100)).matches(MailRegExp, email);
export const passwordValidation = ({ min = 8, max = 256 }) =>
  string().min(min, 'Vui lòng nhập ít nhất 8 ký tự.').max(max, maxMsg(max));
export const confirmPasswordValidation = ({
  ref = 'password',
  matched = 'Mật khẩu và mật khẩu xác nhận không khớp.',
  required = 'Vui lòng nhập.',
}) =>
  stringRequired({ required })
    .oneOf([Yup.ref(ref)], matched)
    .matches(regexValidatePassword, 'Mật khẩu phải có từ 8 ký tự hỗn hợp cả chữ và số .');
