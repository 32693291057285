import * as yup from 'yup';
import { stringRequired } from 'Utils/Validation';

const CampaignOfficialSchema = yup.object().shape({
  name: stringRequired({}).nullable(),
  startDate: stringRequired({}).nullable(),
  endDate: stringRequired({}).nullable(),
  description: stringRequired({}).nullable(),
  prizes: yup.array().min(1, 'Vui lòng nhập.'),
  blackLists: yup.array().nullable(),
});

export default CampaignOfficialSchema;
