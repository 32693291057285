import React, { useState } from 'react';
import { Button, useAccordionButton } from 'react-bootstrap';
import { ToggleButton } from 'Components/Layouts/common';
import { Link } from 'react-router-dom';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const CustomToggle = ({ eventKey, eventItem }: ToggleButton): JSX.Element => {
  const [active, setActive] = useState(false);
  const decoratedOnClick = useAccordionButton(eventKey);

  const buttonCheck = () => {
    setActive(!active);
  };

  return (
    <Button
      className="menu-btn rounded-0 w-100 border-bottom-2"
      variant="link"
      onClick={(e) => {
        decoratedOnClick(e);
        buttonCheck();
      }}
    >
      <Link
        to={eventItem.slug}
        data-bs-toggle="collapse"
        className="nav-link px-2 align-middle d-flex fw-bold"
      >
        <div className="w-100 d-flex align-items-center justify-content-between">
          <span className="d-none d-sm-inline">{eventItem.title}</span>
          <span className="d-none d-sm-inline">{active ? <CaretDownFill /> : <CaretUpFill />}</span>
        </div>
      </Link>
    </Button>
  );
};
export default CustomToggle;
