import { DATE_FORMAT_1 } from 'Constant/Date';
import { flatMap, filter, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { VoucherValue } from 'Types/Page/Voucher/register';
import { handleParseDate } from 'Utils/ParseDate';

interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  render?: (data: any) => void;
}

interface TableColumns {
  columns: ColumnsTypes[];
}

interface Props {
  dataSource: VoucherValue[];
  onDelete?: (data: any) => void;
  onAdd?: (data: any) => void;
  viewOnly?: boolean;
}
export default function VoucherRegisterTable({
  dataSource,
  onDelete,
  viewOnly,
}: Props): JSX.Element {
  const headerTables = useMemo(
    () =>
      [
        {
          columns: [
            {
              className: 'text-center align-middle',
              text: 'Đường dẫn',
              dataIndex: 'url',
              width: 150,
            },
            {
              className: 'text-center align-middle',
              text: 'Thương hiệu',
              dataIndex: 'brand',
              width: 150,
            },
            {
              className: 'text-center align-middle',
              text: 'Giá trị',
              dataIndex: 'price',
              width: 150,
            },
            {
              className: 'text-center align-middle',
              text: 'Ngày mua',
              dataIndex: 'purchaseDate',
              width: 150,
              render: ({ item }) => {
                return (
                  <div className="p-2 h-100 d-flex flex-column justify-content-center">
                    <p>
                      {handleParseDate({
                        date: item.purchaseDate,
                        format: DATE_FORMAT_1,
                      }) ?? ''}
                    </p>
                  </div>
                );
              },
            },
            {
              className: 'text-center align-middle',
              text: 'Ngày hết hạn',
              dataIndex: 'expiredDate',
              width: 150,
              render: ({ item }) => {
                return (
                  <div className="p-2 h-100 d-flex flex-column justify-content-center">
                    <p>
                      {handleParseDate({
                        date: item.expiredDate,
                        format: DATE_FORMAT_1,
                      }) ?? ''}
                    </p>
                  </div>
                );
              },
            },
            {
              className: 'text-center align-middle',
              text: 'Thao tác',
              width: 80,
              dataIndex: 'id',
              render: ({ item }) => {
                return (
                  <div className="ms-auto p-2">
                    {!viewOnly && (
                      <>
                        <Button variant="secondary" onClick={() => onDelete && onDelete(item)}>
                          <Trash size="14" />
                        </Button>
                      </>
                    )}
                  </div>
                );
              },
            },
          ],
        },
      ] as TableColumns[],
    [onDelete, viewOnly],
  );

  const headerFields = flatMap(headerTables, ({ columns }) =>
    filter(columns, (column) => !!column.dataIndex),
  );

  return (
    <div>
      <Table responsive className="table-register-voucher table-radius">
        <thead>
          {headerTables.map((row, rIndex) => {
            return (
              <tr key={rIndex} className="border-bottom">
                {row.columns.map((column, cIndex) => {
                  return (
                    <th key={cIndex} className={column.className} style={{ width: column.width }}>
                      {column.text}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {isEmpty(dataSource) ? (
            <tr>
              <td className="text-center align-middle" colSpan={headerFields?.length}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            dataSource.map((row: any, rIndex: number) => {
              return (
                <tr key={rIndex}>
                  {headerFields.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`${
                        cell.dataIndex === 'id' ? 'text-end' : 'text-center'
                      } pb-0 text-truncate align-middle`}
                      style={{ maxWidth: '80px' }}
                    >
                      {cell.render
                        ? cell.render({ item: { ...row, rIndex }, dataIndex: cell.dataIndex })
                        : (row as any)[cell.dataIndex]}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
}
