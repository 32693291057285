import React from 'react';
import { ReactNode } from 'Components/Layouts/common';
import { Container, Row } from 'react-bootstrap';
import Header from 'Components/Layouts/Header';
import Navigation from 'Components/Layouts/Navigation';
import { MENUS } from 'Constant/Menu';

const Layout = ({ children }: ReactNode): JSX.Element => {
  return (
    <Container fluid className="flex-nowrap vh-100 overflow-hidden">
      <Row>
        <Header />
      </Row>
      <div className="d-flex w-100">
        <div className="sidebar px-0">
          <Navigation menu={MENUS} />
        </div>
        <div className="main-content w-100 px-0 overflow-x-auto">
          <main className="p-0 w-100 vh-layout">{children}</main>
        </div>
      </div>
    </Container>
  );
};

export default Layout;
