import { Menu } from 'Components/Layouts/common';

export const MENUS: Menu[] = [
  {
    title: 'Chiến dịch',
    slug: '#',
    children: [
      {
        title: 'Danh sách chiến dịch',
        slug: '/admin/campaigns',
      },
      {
        title: 'Tạo chiến dịch',
        slug: '/admin/campaigns/register',
      },
    ],
  },
  {
    title: 'Vouchers',
    slug: '#',
    children: [
      {
        title: 'Danh sách voucher',
        slug: '/admin/vouchers',
      },
      {
        title: 'Tạo vouchers',
        slug: '/admin/vouchers/register',
      },
    ],
  },
  {
    title: 'Quản lý tài khoản',
    slug: '#',
    children: [
      {
        title: 'Danh sách người quản lý',
        slug: '/admin/managers',
      },
      {
        title: 'Thông tin tài khoản',
        slug: '/admin/user-information',
      },
    ],
  },
];
