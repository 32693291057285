import React from 'react';

interface RowTypes {
  place: string;
  name?: string;
  amount?: string;
  value?: string;
  totalValue: string;
  className?: string;
  isRowTotal?: boolean;
}

const TableRow = ({
  place,
  name,
  amount,
  value,
  totalValue,
  className,
  isRowTotal,
}: RowTypes): JSX.Element => {
  return (
    <tr className={`${className}`}>
      <td colSpan={isRowTotal ? 4 : 1}>{place}</td>
      {!isRowTotal ? <td>{name}</td> : ''}
      {!isRowTotal ? <td>{value}</td> : ''}
      {!isRowTotal ? <td>{amount}</td> : ''}
      <td>{totalValue}</td>
    </tr>
  );
};

export default TableRow;
