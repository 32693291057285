import React, { PropsWithChildren } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { FormInputNumberProps } from 'Components/Common/Form/FormInputNumber/form-input-number';
import HintTextField from 'Components/Common/Form/HintTextField';

function FormInputNumber({
  label,
  classForm,
  placeholder,
  labelMd,
  colMd,
  name,
  value,
  classInput,
  classCol,
  format,
  onChange,
  errorMessage,
  isReview = false,
  pureType,
  onBlur,
  decimalScale,
  allowLeadingZeros,
  thousandSeparator,
  maxLength,
  minLength,
  allowNegative,
  hintText = '',
  maxValue,
  minValue,
}: PropsWithChildren<FormInputNumberProps>): JSX.Element {
  const { control } = useForm();

  const handleOnchange = (number: string) => {
    if (onChange) {
      if (minValue) {
        if (+number >= +minValue) {
          onChange(number || '');
        } else {
          onChange('');
        }
      } else {
        onChange(number || '');
      }
    }
  };

  const isAllowed = (values: NumberFormatValues): boolean => {
    let isValid = true;
    if (maxLength) {
      isValid = values?.value.length <= maxLength;
    }
    if (minLength) {
      isValid = values?.value.length >= minLength;
    }
    if (maxValue) {
      isValid = (values?.floatValue ?? 0) <= maxValue;
    }
    return isValid;
  };

  return (
    <div className={`mb-3`}>
      <Form.Group as={Row} className={classForm}>
        <Form.Label column md={labelMd}>
          {labelRequire(label)}
        </Form.Label>
        <Col md={colMd} className={classCol}>
          <Controller
            name={name}
            control={control}
            render={() => (
              <NumberFormat
                value={value}
                onValueChange={({ formattedValue, value }) => {
                  handleOnchange(pureType ? value : formattedValue);
                }}
                className={`form-control rounded position-relative ${classInput}`}
                placeholder={placeholder}
                format={format}
                disabled={isReview}
                onBlur={onBlur}
                decimalScale={decimalScale}
                allowLeadingZeros={allowLeadingZeros}
                thousandSeparator={thousandSeparator}
                isAllowed={isAllowed}
                allowNegative={allowNegative}
              />
            )}
          />
        </Col>
      </Form.Group>
      <Row>
        <Col md={labelMd} />
        <Col md={colMd}>
          <HintTextField hintText={hintText} />
          <MessageError classWrapper="w-max-content ms-2" message={errorMessage} />
        </Col>
      </Row>
    </div>
  );
}
export default FormInputNumber;
