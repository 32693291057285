import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppSelector } from 'App/Store';
import StepConfirmView from 'Components/Page/Campaign/RegisterCampaign/Step4/StepConfirmView';
import useCampaign from 'Hooks/useCampaign';
import CampaignOfficialSchema from 'Components/Page/Campaign/RegisterCampaign/Schemas/CampaignOfficialSchema';
import ModalComponent from 'Components/Common/Modal';
import SpinnerComponent from 'Components/Common/SpinnerComponent';

const StepConfirm = (props: any): JSX.Element => {
  const { overview, prizes, step, id } = useAppSelector((state) => state.campaign);
  const {
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampaignOfficialSchema),
  });

  const confirmViewRef = useRef<HTMLDivElement>(null);

  const { saveOrUpdateOfficial, handleChangeStep } = useCampaign();
  const [visible, setVisible] = useState<boolean>(false);

  const handleSaveOfficial = async () => {
    saveOrUpdateOfficial({ overview, prizes, id: props?.campaignId || id });
    setVisible(false);
  };

  useEffect(() => {
    reset({
      ...overview,
      prizes: prizes,
      // blacklists: blacklists,
    });
  }, []);

  return (
    <div>
      <SpinnerComponent isLoading={false} />
      <div id="campaign-detail" ref={confirmViewRef}>
        <StepConfirmView
          overview={overview}
          prizes={prizes}
          // blacklists={blacklists}
          // whitelists={whitelists}
          errors={errors}
        />
        <div id="sp-div" className="d-none" style={{ height: '40px' }}></div>
      </div>
      <ActionButtons
        handleBack={() => handleChangeStep(step - 1)}
        handleSave={async () => {
          (await trigger()) && setVisible(true);
        }}
      />
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={() => handleSaveOfficial()}
        title={!props?.campaignId ? 'Xác nhận đăng ký' : 'Xác nhận cập nhật'}
        confirmText={!props?.campaignId ? 'Đăng ký' : 'Cập nhật'}
      >
        <div>
          <p className="mb-2">{!props?.campaignId ? 'Đăng ký' : 'Cập nhật'} chiến dịch của bạn?</p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default StepConfirm;
