import React from 'react';
import { Table } from 'react-bootstrap';
import TableRow from './TableRow';

export default function PrizesTable(): JSX.Element {
  const prizes = [
    {
      place: 'Giải đặc biệt',
      name: 'Điện thoại iPhone 15 Pro (256GB)',
      value: '29.700.000 VND',
      amount: '06',
      totalValue: '178.200.000',
    },
    {
      place: 'Giải nhất',
      name: 'Đồng hồ thông minh Apple watch SE 2023 40mm',
      value: '7.150.000 VNĐ',
      amount: '08',
      totalValue: '57.200.000',
    },
    {
      place: 'Giải nhì',
      name: 'Tai nghe AirPods 3 với hộp sạc lightning',
      value: '3.960.000 VND',
      amount: '10',
      totalValue: '39.600.000',
    },
    {
      place: 'Giải khuyến khích',
      name: 'Cộng tiền vào tài khoản khuyến mại (KM1T/KM99T)',
      value: '10.000 VND',
      amount: '10.000',
      totalValue: '100.000.000',
    },
    {
      place: 'Tổng giá trị hàng hóa, dịch vụ dùng để khuyến mại (đã bao gồm thuế GTGT)',
      totalValue: '375.000.000 VND',
    },
  ];
  return (
    <>
      <Table className="table-landing-page-prizes" responsive>
        <thead>
          <tr>
            <th>Giải thưởng</th>
            <th>Chi tiết giải thưởng</th>
            <th>
              Trị giá <br /> (đã bao gồm VAT)
            </th>
            <th>Số lượng</th>
            <th>Tổng giá trị (VND)</th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {prizes.map((value, index) => {
            const isRowTotal = prizes.length == index + 1;
            return (
              <TableRow
                key={`item-${index}`}
                name={value.name}
                place={value.place}
                value={value.value}
                amount={value.amount}
                totalValue={value.totalValue}
                isRowTotal={isRowTotal}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
