import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PopupProps {
  title?: string;
  show: boolean;
  handleVisible: () => void;
}

export default function GuidePopup({ show, title, handleVisible }: PopupProps): JSX.Element {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => handleVisible()}
        dialogClassName="modal-90w modal-landing"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-truncate d-flex justify-content-center m-auto"
          >
            <p className="title-header mb-0 text-truncate text-header">{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3 guide-note">
            <p className="pb-1">
              1. Đối với thuê bao MobiFone: Truy cập vào trang web:{' '}
              <a href="https://chiase.mobifone.vn">https://chiase.mobifone.vn</a>.
            </p>
            <p className="pb-1">
              2. Đối với thuê bao Saymee: Truy cập vào trang web:{' '}
              <a href="https://chiase.mobifone.vn/saymee">https://chiase.mobifone.vn/saymee</a>.
            </p>
            <p className="pb-1">
              3. Bắt đầu và nhập số điện thoại nhận được tin nhắn mời tham gia chương trình của
              MobiFone/Saymee. Lưu ý: chương trình chỉ áp dụng cho thuê bao MobiFone và Saymee đủ
              điều kiện tham gia chương trình.
            </p>
            <p className="pb-1">4. Hoàn thành khảo sát và lấy mã quay thưởng.</p>
            <p className="pb-1">5. Nhập mã quay thưởng và tiến hành quay thưởng.</p>
            <p className="pb-1">
              6. Khách hàng nào trúng thưởng, MobiFone sẽ thông báo và tiến hành trao thưởng tùy
              theo địa chỉ lưu trú, sẽ được Công ty Dịch vụ MobiFone Khu vực (CTKV) hẹn trao thưởng
              qua điện thoại.
            </p>
          </div>

          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger fs-18 rounded-pill text-bold" onClick={() => handleVisible()}>
                Đóng lại
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
