import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import { DATE_FORMAT_2, DATE_FORMAT_FOR_DATE_PICKER } from 'Constant/Date';
import dayjs from 'dayjs';
import { CalendarEvent } from 'react-bootstrap-icons';
registerLocale('vi', vi);

export type CPDate = Date | null | undefined;
export interface CPDatePickerTypes {
  selected: CPDate;
  minDate?: CPDate;
  maxDate?: CPDate;
  onChangeDate: (date: CPDate) => void;
  dateFormat?: string;
  placeholder?: string;
  timeFormat?: string;
  showTimeSelect?: boolean;
  disabled?: boolean;
  showTimeSelectOnly?: boolean;
}

const CPDatePicker = ({
  selected,
  minDate = new Date(),
  maxDate,
  onChangeDate,
  dateFormat,
  placeholder,
  timeFormat,
  showTimeSelect,
  disabled,
  showTimeSelectOnly,
}: CPDatePickerTypes): JSX.Element => {
  const handleValidate = (date: Date) => {
    let valid = false;
    if (minDate && maxDate) {
      if (
        dayjs(date).isValid() &&
        dayjs(dayjs(minDate).format(DATE_FORMAT_2)) <= dayjs(date) &&
        dayjs(date) <= dayjs(maxDate)
      ) {
        valid = true;
      }
    }
    if (minDate && !maxDate) {
      if (dayjs(date).isValid() && dayjs(dayjs(minDate).format(DATE_FORMAT_2)) <= dayjs(date)) {
        valid = true;
      }
    }
    return valid;
  };

  const filterPassedTime = (time: any) => {
    const selectedDate = new Date(time);
    if (minDate) {
      const minDateCompare = new Date(dayjs(minDate).format(DATE_FORMAT_2));
      if (
        dayjs(selectedDate).isSame(dayjs(minDateCompare), 'day') &&
        minDateCompare.getTime() > selectedDate.getTime()
      ) {
        return false;
      }
    }
    if (maxDate) {
      const maxDateCompare = new Date(dayjs(maxDate).format(DATE_FORMAT_2));
      if (
        dayjs(selectedDate).isSame(dayjs(maxDateCompare), 'day') &&
        maxDateCompare.getTime() < selectedDate.getTime()
      ) {
        return false;
      }
    }
    return true;
  };

  const handleOnChange = (date: Date) => {
    if (
      dayjs(date).isSame(dayjs(minDate), 'day') &&
      dayjs(date) < dayjs(dayjs(minDate).format(DATE_FORMAT_2))
    ) {
      return onChangeDate(minDate);
    }
    if (
      maxDate &&
      dayjs(date).isSame(dayjs(maxDate), 'day') &&
      dayjs(dayjs(maxDate).format(DATE_FORMAT_2)) < dayjs(date)
    ) {
      return onChangeDate(maxDate);
    }
    if (handleValidate(date)) {
      return onChangeDate(date);
    }
    if (!date) {
      return onChangeDate(date);
    }
    onChangeDate(selected);
  };

  return (
    <div className="position-relative">
      <ReactDatePicker
        className="form-control"
        locale="vi"
        strictParsing
        showTimeSelect={showTimeSelect}
        closeOnScroll={(e) => e.target === document}
        selected={selected}
        timeFormat={timeFormat ?? ''}
        dateFormat={dateFormat ?? DATE_FORMAT_FOR_DATE_PICKER}
        onChange={(date: Date) => handleOnChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        filterTime={filterPassedTime}
        placeholderText={placeholder ?? ''}
        disabled={disabled}
        showTimeSelectOnly={showTimeSelectOnly}
        autoComplete="off"
      />
      <CalendarEvent className="affix-icon" />
    </div>
  );
};

export default CPDatePicker;
