/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';

export interface IQueryParam {
  page?: number | undefined;
  artistSearch?: string;
  variationSearch?: string;
  dateSearch?: string;
  titleSearch?: string;
  endSaleDate?: string;
  startSaleDate?: string;
  startDLDate?: string;
  endDLDate?: string;
  artistSlcId?: string;
  titleSlcId?: string;
  variationSlcId?: string;
}
function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const getParamsFromQueryString = (search: any) => {
  const params = queryString.parse(search);
  const result: any = {};
  for (const [key, value] of Object.entries(params)) {
    const newValue: any = value;
    if (key) {
      if (isJSON(newValue)) {
        result[key] = JSON.parse(newValue);
      } else {
        result[key] =
          /^\d+$/.test(newValue) && newValue[0] != 0 ? parseInt(newValue, 10) : newValue;
      }
    }
  }
  return result;
};
