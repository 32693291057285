import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import HintTextField from 'Components/Common/Form/HintTextField';
import CPDatePicker, {
  CPDatePickerTypes,
  CPDate,
} from 'Components/Common/NewDatePicker/DatePicker';
import { ColSpec } from 'Components/Common/Form/form';

interface FormGroupDateProps extends Omit<CPDatePickerTypes, 'onChangeDate'> {
  label?: string;
  subLabel?: string;
  classForm?: string;
  colClass?: string;
  labelMd: ColSpec;
  colMd: ColSpec;
  onChange: (date: CPDate) => void;
  selected: CPDate;
  colDP?: ColSpec;
  subContent?: string;
  classCol?: string;
  errorMessage?: string;
  hintText?: string;
  showTime?: boolean;
}

const CPFormGroupDate = ({
  label,
  labelMd,
  colMd,
  classForm,
  onChange,
  classCol,
  errorMessage,
  selected,
  minDate,
  maxDate,
  disabled = false,
  showTimeSelectOnly = false,
  placeholder,
  dateFormat,
  colDP = 12,
  subContent,
  hintText = '',
  showTime = false,
}: FormGroupDateProps): JSX.Element => {
  return (
    <Form.Group as={Row} className={`mb-3 ${classForm || ''}`}>
      <Form.Label column md={labelMd}>
        {label && labelRequire(label)}
      </Form.Label>
      <Col md={colMd} className={classCol}>
        <Row>
          <Col md={colDP} className="px-0">
            <CPDatePicker
              dateFormat={dateFormat}
              placeholder={placeholder}
              selected={selected}
              onChangeDate={onChange}
              minDate={minDate ? minDate : new Date()}
              maxDate={maxDate}
              disabled={disabled}
              showTimeSelectOnly={showTimeSelectOnly}
              showTimeSelect={showTime}
            />
            {subContent && <p className="fs-12 mt-1">{subContent}</p>}
          </Col>
          <HintTextField hintText={hintText} />
          <MessageError classWrapper="ms-2 px-0" message={errorMessage} />
        </Row>
      </Col>
    </Form.Group>
  );
};

export default CPFormGroupDate;
