/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { numberRequired, stringRequiredTrim } from 'Utils/Validation';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import CPFormGroupDate from 'Components/Common/Form/FormGroupDate/CPFormGroupDate';
import { dateToISOString, normalizeDate } from 'Utils/DateTime';
import { DATE_FORMAT_FOR_DATE_PICKER_2 } from 'Constant/Date';
import CardWrapper from 'Components/Common/Card/Card';
import { useAppDispatch, useAppSelector } from 'App/Store';
import VoucherRegisterTable from 'Components/Page/Voucher/VoucherRegisterTable';
import { deleteVoucherCode, resetData, updateVoucher } from 'App/Features/Voucher';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

export interface VoucherValue {
  url: string;
  brand: string;
  price: number;
  purchaseDate: string;
  expiredDate: string;
  index?: number;
}

export interface Voucher {
  name: string;
  voucherCode: VoucherValue[];
}

// const schema = yup.object().shape({
//   name: stringRequiredTrim({}).nullable(),
//   url: stringRequiredTrim({}).nullable(),
//   brand: stringRequiredTrim({}).nullable(),
//   price: numberRequired({}).nullable(),
//   purchaseDate: stringRequiredTrim({}).nullable(),
//   expiredDate: stringRequiredTrim({}).nullable(),
// });

const VoucherRegister: React.FunctionComponent<RouteComponentProps> = ({
  history,
}): JSX.Element => {
  const { voucherCodes } = useAppSelector((state) => state.voucher);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation<any>();

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: manager,
    // resolver: yupResolver(schema),
  });

  const onsubmit = async (voucher: any) => {
    dispatch(updateVoucher(voucher));
    reset({
      name: voucher.name,
      voucherCode: {
        url: '',
        brand: '',
        price: '',
        purchaseDate: '',
        expiredDate: '',
      },
    });
  };

  const handleRemoveVoucherCode = async (item: any) => {
    dispatch(deleteVoucherCode(item));
  };

  useEffect(
    () => {
      history?.listen(() => {
        dispatch(resetData());
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (location.pathname !== '/vouchers/register') {
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Container className="mt-4">
      {/* <SpinnerComponent isLoading={isLoading} /> */}
      <Row>
        <Col md="12" className="px-0">
          <BoxForm title="Tạo voucher">
            <>
              <Form onSubmit={handleSubmit(onsubmit)} id="create-voucher">
                <FormGroupInput
                  value={watch('name')}
                  labelMd="2"
                  colMd="10"
                  label="Tên:"
                  register={{ ...register('name') }}
                  onChange={(value) => setValue('name', value)}
                  errorMessage={errors.name?.message}
                />
                <hr className="my-3" />
                <FormGroupInput
                  value={watch('voucherCode.url')}
                  labelMd="2"
                  colMd="10"
                  label="Đường dẫn:"
                  onChange={(value) => setValue('voucherCode.url', value)}
                  register={{ ...register('voucherCode.url') }}
                  errorMessage={errors.url?.message}
                />
                <FormGroupInput
                  value={watch('voucherCode.brand')}
                  labelMd="2"
                  colMd="10"
                  label="Thương hiệu:"
                  onChange={(value) => setValue('voucherCode.brand', value)}
                  register={{
                    ...register('voucherCode.brand'),
                  }}
                  errorMessage={errors.brand?.message}
                  maxLength={13}
                />
                <FormInputNumber
                  name="voucherCode.price"
                  labelMd="2"
                  colMd="10"
                  label="Giá trị:"
                  pureType={true}
                  decimalScale={0}
                  maxLength={10}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  value={watch('voucherCode.price')}
                  onChange={(value: any) => setValue('voucherCode.price', value)}
                  errorMessage={errors.type1?.price?.message}
                />
                <CPFormGroupDate
                  label="Ngày mua:"
                  labelMd="2"
                  colMd="6"
                  placeholder="ngày (yyyy/mm/dd)"
                  selected={normalizeDate(watch('voucherCode.purchaseDate'))}
                  dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
                  onChange={(date: any) =>
                    setValue('voucherCode.purchaseDate', dateToISOString(date))
                  }
                  errorMessage={errors?.purchaseDate?.message}
                  minDate={new Date()}
                />
                <CPFormGroupDate
                  label="Ngày hết hạn:"
                  labelMd="2"
                  colMd="6"
                  placeholder="ngày (yyyy/mm/dd)"
                  selected={normalizeDate(watch('voucherCode.expiredDate'))}
                  dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
                  onChange={(date: any) =>
                    setValue('voucherCode.expiredDate', dateToISOString(date))
                  }
                  errorMessage={errors?.expiredDate?.message}
                  minDate={new Date()}
                />
              </Form>
            </>
            <div className="d-flex pb-4">
              <div className="col-md-4 px-0"></div>
              <div className="col-md-4 px-0 d-flex justify-content-center">
                <Button
                  className="mt-4 mb-4 btn-focus-none btn-curator-register"
                  variant="primary"
                  type="submit"
                  form="create-voucher"
                >
                  Thêm
                </Button>
              </div>
              <div className="col-md-4 px-0 d-flex justify-content-end">
                <Button
                  className="mt-4 mb-4 ps-4 btn-focus-none btn-curator-register"
                  variant="primary"
                >
                  Tải lên file excel
                </Button>
              </div>
            </div>
          </BoxForm>
        </Col>
      </Row>
      <Row>
        <CardWrapper title="Danh sách voucher" className="card-wrapper-gray">
          <VoucherRegisterTable
            dataSource={voucherCodes}
            onDelete={(item) => handleRemoveVoucherCode(item)}
          />
        </CardWrapper>
      </Row>
    </Container>
  );
};

export default withRouter(VoucherRegister);
