import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { InputEvent } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { InputProps } from 'Components/Common/Form/FormGroupTextArea/form-group-text-area';
import HintTextField from 'Components/Common/Form/HintTextField';

export default function FormGroupTextArea(props: InputProps): JSX.Element {
  const {
    label,
    subLabel,
    classForm,
    errorMessage,
    placeholder,
    labelMd,
    colMd,
    value,
    register,
    onChange,
    hintText = '',
    disabled = false,
    isReview = false,
    maxLength,
    messageWrapper,
    classInput,
    onKeyPress,
    rows = 3,
  } = props;

  const handleOnchange = (e: InputEvent) => {
    if (onChange && !isReview) {
      onChange(e.target.value);
    }
  };

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd}>
        {labelRequire(label)}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd}>
        <textarea
          {...register}
          value={value ?? ''}
          onChange={handleOnchange}
          placeholder={placeholder}
          disabled={disabled || isReview}
          maxLength={maxLength}
          className={`${classInput} form-control`}
          autoComplete="off"
          rows={rows}
          onKeyPress={onKeyPress}
        />
        <HintTextField hintText={hintText} />
        <MessageError classWrapper={messageWrapper} message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
