interface CheckboxOption {
  label: string;
  checked: boolean;
  value: number;
  subLabel?: string;
  disabled?: boolean;
}

enum CampaignSteps {
  Overview = 0,
  SignUpRewards = 1,
  // WinningConditions = 2, // comment for hide blacklist
  Confirm = 2,
}

enum CampaignStatus {
  Draft = 0,
  Official = 1,
}

export enum UserCampaignStatus {
  FAIL,
  PASS,
  REJECT,
}
const prizeOptionsType: CheckboxOption[] = [
  {
    label: 'Mặc định',
    checked: false,
    value: 0,
  },
  {
    label: 'Voucher',
    checked: false,
    value: 1,
  },
];

const prizePickType: CheckboxOption[] = [
  {
    label: 'Tất cả',
    checked: false,
    value: 0,
  },
  {
    label: 'Mobifone',
    checked: false,
    value: 1,
  },
  {
    label: 'Saymee',
    checked: false,
    value: 2,
  },
];

const limitUserSetting: CheckboxOption[] = [
  {
    label: 'Không giới hạn',
    checked: false,
    value: 0,
  },
  {
    label: 'Giới hạn',
    checked: false,
    value: 1,
  },
];

const blacklistOptionsType: CheckboxOption[] = [
  {
    label: 'Số điện thoại',
    checked: false,
    value: 0,
  },
  {
    label: 'Vị trí',
    checked: false,
    value: 1,
  },
  {
    label: 'Địa chỉ IP',
    checked: false,
    value: 2,
  },
];

const whitelistOptionsType: CheckboxOption[] = [
  {
    label: 'Số điện thoại',
    checked: false,
    value: 0,
  },
  {
    label: 'Vị trí',
    checked: false,
    value: 1,
  },
  {
    label: 'Địa chỉ IP',
    checked: false,
    value: 2,
  },
];

const NOTE_PRIZE_TYPE = [
  'Phần thưởng mặc định là các phần thưởng bằng hiện vật.',
  'Phần thưởng vourcher để sử dụng cho các dịch vụ bên ngoài.',
];

const NOTE_POSITION_PRIZE = 'Vị trí của phần thưởng trên vòng quay trúng thưởng';

const CAMPAIGN_INITIAL_STATE = {
  step: 0,
  overview: {
    name: '',
    startDate: null,
    endDate: null,
    description: '',
    isDeletable: true,
    limitUser: 0,
  },
  prizes: [],
  blacklists: [],
  whitelists: [],
};

const FIELD_SIZE = {
  label: 2,
  value: 10,
};

const SignUpRewardInitialState = {
  name: '',
  type: 0,
  positionWheel: null,
  settingProbabilities: [],
  pickType: 0,
  quantity: '',
  probability: '',
  startDate: null,
  endDate: null,
};

const defaultConditions = {
  enabled: true,
  option1: false,
  option2: false,
  option3: false,
  option4: false,
};

export {
  CampaignSteps,
  NOTE_PRIZE_TYPE,
  CAMPAIGN_INITIAL_STATE,
  FIELD_SIZE,
  SignUpRewardInitialState,
  defaultConditions,
  CampaignStatus,
  prizeOptionsType,
  blacklistOptionsType,
  whitelistOptionsType,
  NOTE_POSITION_PRIZE,
  prizePickType,
  limitUserSetting,
};

export type { CheckboxOption };
