/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
interface UserLogin {
  email: string;
  password: string;
}

// interface IResetPass {
//   code?: string;
//   password: string;
//   confirmPassword: string;
// }

interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export const AuthApi = {
  loginApi: (params: UserLogin) => brAxios.post('/login', params),
  // forgetPassword: (dataEmail: { email: string }) => brAxios.post('/forgot-password', dataEmail),
  changePassword: (params: ChangePasswordParams) => brAxios.post('/change-password', params),
  // resetPassword: (dataEmail: IResetPass) => brAxios.post('reset-password', dataEmail),
};
