import { ColSpec } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ViewOnlyField = ({
  labelSize,
  valueSize,
  label,
  value,
  isMessageInside = false,
  errorMessage,
  prevLine,
}: {
  labelSize: ColSpec;
  valueSize: ColSpec;
  label?: string;
  value: string;
  isMessageInside?: boolean;
  errorMessage?: string;
  prevLine?: boolean;
}): JSX.Element => {
  return (
    <div className="mb-3">
      <Row>
        <Col className="px-0 text-break" md={labelSize}>
          {label}
        </Col>
        <Col className="ps-1 pe-0" md={valueSize}>
          <div className={`${prevLine ? 'text-prev-wrap' : 'text-truncate'}`}>{value}</div>
          {isMessageInside && errorMessage && (
            <div>
              <MessageError classWrapper="me-0" message={errorMessage} />
            </div>
          )}
        </Col>
      </Row>
      {!isMessageInside && errorMessage && (
        <Row>
          <Col className="px-0" md={labelSize} />
          <Col className="ps-1 pe-0" md={valueSize}>
            <MessageError classWrapper="me-0" message={errorMessage} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ViewOnlyField;
