/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { handleParseDate } from 'Utils/ParseDate';
import { Button } from 'react-bootstrap';
import LoadMoreUserWin from './LoadMoreUserWin';
interface IListCP {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function CampaignTable({ dataSource }: { dataSource: IListCP }): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            width: '50',
          },
          {
            Header: 'Tên',
            accessor: 'name',
            width: '300',
            Cell: ({ row }: Cell<IListCP>) => {
              return (
                <Link
                  to={{
                    pathname: `/admin/campaigns/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.name && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start">{row.original.name}</span>
                </Link>
              );
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.name ?? defaultVal).localeCompare(
                rowB?.original?.name ?? defaultVal,
              );
            },
          },
          {
            Header: 'Phần thưởng',
            accessor: 'prizes',
            width: '300',
            Cell: ({ row }: Cell<IListCP>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column">
                  {row.original?.prizes.length ? (
                    row.original?.prizes.map((prize: any, cIndex: number) => {
                      return (
                        <>
                          <p key={cIndex} className="text-start">
                            <b>Phần thưởng: </b>
                            {prize.name}
                          </p>
                          <br />
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center">-</div>
                  )}
                </div>
              );
            },
          },
          {
            Header: 'Thời gian diễn ra',
            accessor: 'empty',
            width: '300',
            Cell: ({ row }: Cell<IListCP>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column justify-content-center">
                  <p>
                    Bắt đầu:{' '}
                    {handleParseDate({
                      date: row.original?.startDate,
                      format: DATE_FORMAT_2,
                    }) ?? '-'}
                  </p>
                  <p>
                    Kết thúc:{' '}
                    {handleParseDate({
                      date: row.original?.endDate,
                      format: DATE_FORMAT_2,
                    }) ?? '-'}
                  </p>
                </div>
              );
            },
          },
          {
            width: '475',
            Header: 'Người chơi trúng giải',
            accessor: 'usersWin',
            Cell: ({ row }: Cell<IListCP>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column justify-content-start">
                  {row.original?.userCampaigns.length ? (
                    <LoadMoreUserWin
                      userCampaigns={row.original.userCampaigns}
                      prizes={row.original.prizes}
                    />
                  ) : (
                    <div className="text-center">
                      <p>Chưa có người trúng thưởng</p>
                    </div>
                  )}
                  <div className="d-flex flex-wrap justify-content-between mt-2">
                    <Button className="fs-12" variant="secondary">
                      <Link
                        to={`/admin/campaigns/${row.original.id}/users`}
                        className="text-white text-decoration-none w-100 h-100"
                      >
                        Danh sách người tham gia
                      </Link>
                    </Button>
                  </div>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <TableCommon
        classTable="table-serial-number"
        heightBody="calc(100vh - 200px)"
        data={dataSource}
        columns={columns}
        messageNoData="Không có kết quả tìm kiếm."
      />
    </>
  );
}
