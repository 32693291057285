import { REGEX_PHONE_VN } from 'Constant';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MessageError from '../MessageError';
import { LandingPageApis } from 'Datasource/LandingPage';
import SpinnerComponent from '../SpinnerComponent';
import ErrorPopup from './ErrorPopup';
import { AxiosError } from 'axios';

interface PopupProps {
  show: boolean;
  isSaymee: boolean;
  personalID: string;
  handleVisible: () => void;
}
export default function VerifyPhonePopup({
  show,
  isSaymee,
  personalID,
  handleVisible,
}: PopupProps): JSX.Element {
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState<string[]>([]);
  const [isShowError, setIsShowError] = useState(false);

  const handleOnchange = (e: any) => {
    const phone = e.target.value;
    if (!phone.match(REGEX_PHONE_VN)) {
      setMessage('Vui lòng nhập đúng số điện thoại');
      setIsDisable(true);
    } else {
      setMessage('');
      setIsDisable(false);
    }
    setPhone(e.target.value);
  };

  const campaignId = process.env.REACT_APP_CAMPAIGN_ID as string;

  const handleVerify = async () => {
    try {
      setLoading(true);
      setIsDisable(true);
      const { data } = await LandingPageApis.verifyPhone({
        phoneNumber: phone,
        campaignId: +campaignId,
        isSaymee,
        personalID,
      });
      setIsDisable(false);
      setLoading(false);
      window.location.href = data;
    } catch (error) {
      setLoading(false);
      setIsDisable(false);

      const message = [] as string[];
      const { response } = error as AxiosError;
      response?.data?.message?.map((mes: string) => {
        message.push(mes);
      });
      setMessageError(message);
      setIsShowError(true);
      return;
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Modal
        show={show}
        size="xl"
        onHide={() => handleVisible()}
        dialogClassName="modal-100w modal-landing modal-landing-verify-phone"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body>
          <div className="px-3">
            {isSaymee ? (
              <>
                <div>
                  Hello “<span className="text-strong">Mai đẹt-ti-ni</span>”, chào mừng các bồ đến
                  với thử thách{` "`}
                  <span className="text-strong">Trải nghiệm liền tay – Nhận ngay quà khủng</span>
                  {`"`}.
                </div>
                <div className="pt-3">
                  Để chúng mình mãi mận – mãi keo, Saymee muốn mời các bồ tham gia hoàn thành khảo
                  sát và muốn lắng nghe ý kiến chia sẻ của các bồ để tặng các phần quà hết nước chấm
                  đến mọi ngừi. Ai nhanh chân thì còn, mà ai chậm chân sợ hết thì quà trong kho còn
                  đầy.
                </div>
                <div className="pt-3">
                  Để tham gia chương trình nhận quà lần này thì các bồ chỉ cần nhập số telephone
                  Saymee vào là được nha:{' '}
                </div>
                <div className="pt-3">
                  <span className="text-strong">Lưu ý: </span>Số thuê bao này sẽ được sử dụng để
                  nhận giải thưởng
                </div>
              </>
            ) : (
              <>
                <div>
                  Chào mừng Quý khách đến với chương trình{` "`}
                  <span className="text-strong">Trải nghiệm liền tay – Nhận ngay quà khủng</span>
                  {`"`}.
                </div>
                <div className="pt-3">
                  Quý khách đồng ý tham gia chương trình này, vui lòng nhập số điện thoại thuê bao
                  MobiFone để bắt đầu.
                </div>
                <div className="pt-3">
                  <span className="text-strong">Lưu ý: </span>Số thuê bao này sẽ được sử dụng để
                  nhận giải thưởng
                </div>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center pt-3 ">
            <Form.Control
              className="phone-number w-70 rounded-2"
              value={phone}
              onChange={handleOnchange}
              placeholder="Vui lòng nhập số điện thoại"
              type="text"
              maxLength={15}
            />
          </div>
          <MessageError classWrapper="d-flex justify-content-center" message={message} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around px-5">
          <Button
            className="btn-cancel text-bold"
            variant="secondary"
            type="button"
            onClick={() => handleVisible()}
          >
            ĐÓNG LẠI
          </Button>
          <Button
            className="btn-verify text-bold"
            variant="primary"
            onClick={handleVerify}
            disabled={isDisable}
          >
            BẮT ĐẦU
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorPopup
        show={isShowError}
        message={messageError}
        handleVisible={() => setIsShowError(false)}
      />
    </>
  );
}
