import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { CampaignSteps } from 'App/Features/Campaign';
import { useAppSelector } from 'App/Store';
import { useParams } from 'react-router-dom';
import ModalComponent from 'Components/Common/Modal';

interface ActionButtonsProps {
  handleNext?: () => void;
  handleBack?: () => void;
  handleDelete?: () => void;
  handleSave?: () => void;
  isVisibleDelete?: boolean;
  isVisibleSave?: boolean;
}

const ActionButtons = ({
  handleNext,
  handleBack,
  handleDelete,
  handleSave,
}: ActionButtonsProps): JSX.Element => {
  const { step, overview } = useAppSelector((state) => state.campaign);
  const { id }: { id: string } = useParams();
  const [visible, setVisible] = useState<boolean>(false);
  const handleBackStep = () => {
    handleBack && handleBack();
  };
  const handleNextStep = () => {
    handleNext && handleNext();
  };

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center py-3">
        {step === CampaignSteps.Overview && !!id && (
          <Button
            variant="danger"
            className="btn-action"
            onClick={() => setVisible(true)}
            disabled={!overview?.isDeletable}
          >
            Xóa chiến dịch
          </Button>
        )}
        {step !== CampaignSteps.Overview && (
          <Button
            className="btn-action"
            onClick={() => {
              handleBackStep();
            }}
          >
            Quay lại bước trước
          </Button>
        )}
        {step !== CampaignSteps.Confirm && (
          <Button className="btn-action ms-3" onClick={() => handleNextStep()}>
            Chuyển sang bước tiếp theo
          </Button>
        )}
        {step === CampaignSteps.Confirm && (
          <Button className="btn-action ms-3" onClick={handleSave}>
            {!id ? 'Đăng ký' : 'Cập nhật'}
          </Button>
        )}
      </Row>
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => {
          handleDelete && handleDelete();
          setVisible(false);
        }}
        title="Xác nhận xóa"
        confirmText="Xóa bỏ"
      >
        <div>
          <p className="mb-2">Bạn có chắc chắn muốn xóa chiến dịch?</p>
        </div>
      </ModalComponent>
    </>
  );
};

export default ActionButtons;
