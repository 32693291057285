/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form, Modal as FormModal, Button, Row, Col } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import Modal from 'Components/Common/Modal';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import {
  SignUpRewardInitialState,
  prizeOptionsType,
  NOTE_PRIZE_TYPE,
  NOTE_POSITION_PRIZE,
  prizePickType,
} from 'Constant/Campaign';
import { updatePrizes, deletePrize } from 'App/Features/Campaign';
import { useAppDispatch } from 'App/Store';
import schema from 'Components/Page/Campaign/RegisterCampaign/Schemas/RegisterSchema';
import { last, omit } from 'lodash';
import ModalComponent from 'Components/Common/Modal';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import CpFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/CpFormGroupDateRange';
import { normalizeDate } from 'Utils/DateTime';
import { DateType, PrizeProbability } from 'Types/Page/Campaign/RegisterCampaign';
import TableProbabilities from './TableProbabilities';

interface SignUpRewardProps {
  isDisable?: boolean;
  currentPrize: any;
  onCloseModal?: any;
  handleShow?: () => void;
}

const SignUpRewardPopup = ({
  isDisable,
  onCloseModal,
  handleShow,
  currentPrize,
}: SignUpRewardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [indexProbabilityDelete, setIndexProbabilityDelete] = useState<any>(null);
  const [showModalDeleteProbability, setShowModalDeleteProbability] = useState<boolean>(false);
  const [index, setIndex] = useState<any>(undefined);
  const [indexProbability, setIndexProbability] = useState<any>(undefined);
  const [prizeProbabilities, setPrizeProbabilities] = useState<PrizeProbability[]>([]);

  const methods = useForm<any>({
    defaultValues: SignUpRewardInitialState,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    const { type, name, positionWheel, id } = values;
    const prize = {
      id,
      index: index,
      name,
      type,
      positionWheel,
      settingProbabilities: prizeProbabilities,
    };
    dispatch(updatePrizes({ prize }));
    handleShow && handleShow();
  };

  const handleChangeDate = (date: DateType, type: string) => {
    setValue(`${type}` as any, date);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = methods;

  const handleAdd = () => {
    const formData = getValues();
    const probability = {
      probability: formData.probability as string,
      pickType: formData.pickType as number,
      quantity: formData.quantity as number,
      startDate: formData.startDate as string,
      endDate: formData.endDate as string,
    } as PrizeProbability;
    setValue('pickType', 0);
    setValue('probability', '');
    setValue('quantity', '');
    setValue('startDate', null);
    setValue('endDate', null);

    if (indexProbability == undefined) {
      const index = last(prizeProbabilities)?.index;
      probability.index = index !== undefined ? index + 1 : 0;
      setPrizeProbabilities([...prizeProbabilities, probability]);
      return;
    }

    probability.index = indexProbability;

    const probabilities = prizeProbabilities.map((p: PrizeProbability) => {
      if (indexProbability == p.index) {
        // case update existed in db
        if (p?.id) {
          probability.id = p.id;
        }

        return probability;
      }

      return p;
    });

    setPrizeProbabilities(probabilities);
    setIndexProbability(undefined);
  };

  const handleUpdateProbability = (item: PrizeProbability) => {
    setValue('pickType', item.pickType);
    setValue('probability', item.probability);
    setValue('quantity', item.quantity);
    setValue('startDate', item.startDate);
    setValue('endDate', item.endDate);
    setIndexProbability(item.index);
  };

  const deleteProbability = () => {
    if (indexProbabilityDelete !== undefined) {
      const probabilities = prizeProbabilities.filter((p) => {
        return p.index != indexProbabilityDelete;
      });
      setPrizeProbabilities(probabilities);
      setIndexProbabilityDelete(undefined);
    }
    setShowModalDeleteProbability(false);
  };

  const showPopupDeleteProbability = (item: PrizeProbability) => {
    setShowModalDeleteProbability(true);
    setIndexProbabilityDelete(item.index);
  };

  useEffect(() => {
    const { prize } = currentPrize;
    let tmpPrize: any = {
      id: prize?.id,
      name: prize?.name,
      positionWheel: prize?.positionWheel,
      type: prize?.type ?? 0,
    };
    if (prize) setIndex(prize.index);
    if (prize?.settingProbabilities) setPrizeProbabilities(prize.settingProbabilities);
    const typeValue = omit(prize, ['type', 'name', 'positionWheel']);
    tmpPrize = { ...SignUpRewardInitialState, ...tmpPrize, ...typeValue };
    reset(tmpPrize);
  }, [reset, currentPrize]);

  const handleCancel = () => {
    handleShow && handleShow();
  };

  return (
    <>
      <Modal
        show={currentPrize?.visible}
        onCloseModal={onCloseModal}
        dialogClassName="modal-award"
        footerClassName="d-none"
        title={'Cài đặt phần thưởng'}
        showCancelButton={false}
        isReviewModal
        size="xl"
      >
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className={isDisable ? 'pe-none user-select-none' : 'pe-auto user-select-auto'}
          >
            <FormGroupInput
              label="Tên giải thưởng"
              labelMd="2"
              colMd="10"
              value={watch('name')}
              register={register('name')}
              onChange={(value) => setValue('name', value)}
              classForm="form-with-label-nowrap"
              errorMessage={errors.name?.message}
            />
            <CheckBoxs
              id="version-indent"
              labelForm="Loại phần thưởng"
              name="type"
              labelMd="2"
              colMd="10"
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.type?.message}
              options={prizeOptionsType}
              value={watch(`type`)}
              onChange={({ value }) => setValue('type', value)}
              disabled={true}
              hintText={NOTE_PRIZE_TYPE[watch('type')]}
              classOption="pe-4"
            />
            <FormInputNumber
              name="positionWheel"
              labelMd="2"
              colMd="10"
              label="Vị trí"
              pureType={true}
              decimalScale={0}
              maxLength={10}
              allowLeadingZeros={true}
              allowNegative={false}
              value={watch('positionWheel')}
              errorMessage={errors.positionWheel?.message}
              onChange={(value) => setValue('positionWheel', value)}
              hintText={NOTE_POSITION_PRIZE}
            />
            <div className="mt-5">
              <div className="d-flex align-items-center">
                <span className="fw-bold">Cài đặt thời gian trúng giải</span>
              </div>
              <Row>
                <hr className="my-3" />
                <Col className="mx-5">
                  <CheckBoxs
                    id="pick-type"
                    labelForm="Đối tượng"
                    name="pickType"
                    labelMd="2"
                    colMd="10"
                    classForm="form-with-label-nowrap mb-3"
                    errorMessage={errors.pickType?.message}
                    options={prizePickType}
                    value={watch(`pickType`)}
                    onChange={({ value }) => setValue('pickType', value)}
                    hintText="Đối tượng có thể trúng giải"
                    classOption="pe-4"
                  />
                  <FormInputNumber
                    name="quantity"
                    labelMd="2"
                    colMd="3"
                    label="Số lượng"
                    pureType={true}
                    decimalScale={0}
                    maxLength={10}
                    allowLeadingZeros={true}
                    allowNegative={false}
                    value={watch('quantity')}
                    onChange={(value: any) => setValue('quantity', value)}
                    errorMessage={errors.type1?.quantity?.message}
                  />
                  <FormInputNumber
                    name="probability"
                    labelMd="2"
                    colMd="3"
                    label="Xác suất"
                    pureType={true}
                    decimalScale={2}
                    maxLength={5}
                    allowLeadingZeros={true}
                    classForm="probability-of-prize"
                    allowNegative={false}
                    value={watch('probability')}
                    onChange={(value: any) => setValue('probability', value)}
                    errorMessage={errors.type1?.probability?.message}
                  />
                  <CpFormGroupDateRange
                    label="Thời gian diễn ra"
                    labelMd="2"
                    colMd="8"
                    placeholderStart="Thời gian bắt đầu"
                    placeholderEnd="Thời gian kết thúc"
                    showTimeSelect
                    startDate={normalizeDate(watch('startDate'))}
                    endDate={normalizeDate(watch('endDate'))}
                    onChangeStartDate={(date: any) => {
                      handleChangeDate(date, 'startDate');
                      if (!date) {
                        handleChangeDate(null, 'endDate');
                      }
                    }}
                    onChangeEndDate={(date: any) => {
                      handleChangeDate(date, 'endDate');
                    }}
                    endDateDisabled={!watch('startDate')}
                    minStartDate={new Date()}
                    maxStartDate={normalizeDate(watch('endDate'))}
                    errorStartDate={errors?.startDate?.message}
                    errorEndDate={errors?.endDate?.message}
                  />
                </Col>
                <hr className="my-3" />
              </Row>
              <div>
                <Button
                  variant="primary"
                  className="md-2 mb-4"
                  type="button"
                  onClick={() => {
                    handleAdd();
                  }}
                  disabled={
                    watch('pickType') == null ||
                    !watch('quantity') ||
                    !watch('probability') ||
                    !watch('startDate') ||
                    !watch('endDate')
                  }
                >
                  {indexProbability != undefined ? 'Cập nhật cài đặt' : 'Thêm cài đặt'}
                </Button>
              </div>

              <TableProbabilities
                handleEdit={(item) => handleUpdateProbability(item)}
                handleRemove={(item) => showPopupDeleteProbability(item)}
                prizeProbabilities={prizeProbabilities}
              />
            </div>
          </Form>
        </FormProvider>

        <FormModal.Footer>
          <Button
            variant="danger"
            className="btn-action"
            onClick={() => {
              setVisible(true);
            }}
          >
            Xóa phần thưởng
          </Button>
          <Button variant="secondary" className="btn-action" onClick={() => handleCancel()}>
            Hủy bỏ
          </Button>
          <Button
            variant="primary"
            className="btn-action"
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Lưu lại
          </Button>
        </FormModal.Footer>
      </Modal>
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => {
          dispatch(
            deletePrize({
              prize: currentPrize.prize,
            }),
          );
          handleShow && handleShow();
          setVisible(false);
        }}
        title="Xác nhận xóa!"
        confirmText="Xóa bỏ"
      >
        <div>
          <p className="mb-2">Bạn có chắc muốn xóa phần thưởng?</p>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showModalDeleteProbability}
        onCloseModal={() => setShowModalDeleteProbability(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => deleteProbability()}
        title="Xác nhận xóa!"
        confirmText="Xóa bỏ"
      >
        <div>
          <p className="mb-2">Bạn có chắc muốn xóa cài đặt?</p>
        </div>
      </ModalComponent>
    </>
  );
};
export default SignUpRewardPopup;
