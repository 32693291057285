/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import { Button, Row } from 'react-bootstrap';
import SignUpRewardPopup from './SignUpRewardPopup';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppSelector } from 'App/Store';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import TableAward from './TableAward';

interface prizeType {
  visible: boolean;
  prize?: any;
}

const StepSignUpRewards = forwardRef((props: any, ref: any): JSX.Element => {
  const { step, overview, prizes } = useAppSelector((state) => state.campaign);
  const { handleChangeStep, isLoading } = useCampaign();

  const [currentPrize, setCurrentPrize] = useState<prizeType>({
    visible: false,
    prize: {},
  });

  const handleShowBonusModal = ({ visible, prize }: prizeType) => {
    visible
      ? setCurrentPrize({ visible, prize })
      : setCurrentPrize({
          visible: false,
          prize: {},
        });
  };

  const handleNext = () => {
    handleChangeStep(step + 1);
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent() {
      handleNext();
    },
  }));

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <div className="register-campaign-step-3">
        <CardWrapper title={`Chiến dịch: ${overview?.name}`}>
          <Row className="mb-3">
            <div className="d-flex">
              <div className="me-3 align-self-end">
                <Button
                  className="btn-action"
                  onClick={() =>
                    handleShowBonusModal({
                      visible: true,
                    })
                  }
                >
                  Đăng ký giải thưởng
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            <TableAward
              handleShow={(prize: any) => {
                handleShowBonusModal({
                  visible: true,
                  prize,
                });
              }}
              prizes={prizes}
            />
          </Row>
        </CardWrapper>
        <ActionButtons handleBack={() => handleChangeStep(step - 1)} handleNext={handleNext} />
        {currentPrize.visible && (
          <SignUpRewardPopup
            currentPrize={currentPrize}
            handleShow={() => {
              handleShowBonusModal({ visible: false });
            }}
          />
        )}
      </div>
    </>
  );
});

export default StepSignUpRewards;
