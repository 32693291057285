import React from 'react';
import { Image } from 'react-bootstrap';
import IconFacebook from 'Static/Icons/LandingPage/ic_facebook.svg';
import IconTiktok from 'Static/Icons/LandingPage/ic_tiktok.svg';
import IconIns from 'Static/Icons/LandingPage/ic_instagram.svg';
import QR from 'Static/Icons/LandingPage/saymee_qr.png';
import AppleStore from 'Static/Icons/LandingPage/app-store-apple-logo.png';
import GooglePlay from 'Static/Icons/LandingPage/google-play-badge.png';
import LogoSM from 'Static/Images/logo_saymee.svg';

export default function FooterSM(): JSX.Element {
  return (
    <>
      <footer className="footer-saymee">
        <div className="desktop">
          <div className="d-flex mx-5 py-4">
            <div className="col-lg-4 d-flex flex-column pe-3">
              <span className="text-strong mb-3">Tổng công ty Viễn thông MobiFone</span>
              <span>
                Địa chỉ: Tòa nhà MobiFone - Lô VP1, Phường Yên Hòa, Quận Cầu Giấy, Thành phố Hà Nội.
              </span>
              <span>
                Mã số doanh nghiệp: 0100686209, đăng ký lần đầu ngày 20/9/2010, đăng ký thay đổi lần
                thứ 9 ngày 27/7/2018, cấp bởi Sở KHĐT thành phố Hà Nội.
              </span>
              <span>Tổng đài hỗ trợ: 0931.000.666 (Trong giờ hành chính) hoặc 9394 (24/7)</span>
            </div>
            <div className="col-lg-2 d-flex flex-column">
              <span className="mb-3 text-strong">Giới thiệu</span>
              <a href="https://saymee.vn/gioi-thieu" target="_blank" rel="noreferrer">
                <span>Giới thiệu Saymee</span>
              </a>
              <a href="https://saymee.vn/" target="_blank" className="mb-3" rel="noreferrer">
                <span>Tuyển dụng</span>
              </a>
              <span className="mb-3 text-strong">Dịch vụ di động</span>
              <a href="https://saymee.vn/mua-sim" target="_blank" rel="noopener noreferrer">
                <span>Mua SIM Saymee</span>
              </a>
              <a href="https://saymee.vn/san-pham" target="_blank" rel="noopener noreferrer">
                <span>Gói cước Saymee</span>
              </a>
              <a
                href="https://saymee.vn/san-pham%23service"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Gói dịch vụ Saymee</span>
              </a>
            </div>
            <div className="col-lg-2 d-flex flex-column pe-3">
              <span className="text-strong mb-3">Điều khoản & bảo mật</span>
              <a
                href="https://saymee.vn/dieu-khoan-su-dung"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Điều khoản sử dụng</span>
              </a>
              <a
                href="https://saymee.vn/dieu-kien-thanh-toan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Điều khoản và Điều kiện giao dịch</span>
              </a>
              <a
                href="https://saymee.vn/bao-mat-thong-tin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Bảo mật thông tin</span>
              </a>
              <a
                href="https://saymee.vn/bao-mat-thanh-toan"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Bảo mật thanh toán</span>
              </a>
              <a
                href="https://saymee.vn/chinh-sach-giao-hang"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Chính sách giao hàng</span>
              </a>
              <a
                href="https://saymee.vn/chinh-sach-thanh-toan-hoan-tien"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Chính sách thanh toán và hoàn tiền</span>
              </a>
              <a
                href="https://saymee.vn/phan-dinh-trach-nhiem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  Phân định trách nhiệm của thương nhân, tổ chức cung ứng dịch vụ logistics về cung
                  cấp chứng từ hàng hoá trong quá trình giao nhận
                </span>
              </a>
            </div>
            <div className="col-lg-2">
              <div className="d-flex flex-column">
                <span className="text-strong mb-3">Hỗ trợ khách hàng</span>
                <a href="https://saymee.vn/ho-tro" target="_blank" rel="noopener noreferrer">
                  <span>Câu hỏi thường gặp</span>
                </a>
                <a
                  href="https://saymee.vn/support%23hotline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Liên hệ Hotline</span>
                </a>
                <a
                  href="https://saymee.vn/support%23reflect"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-3"
                >
                  <span>Góp ý cho Saymee</span>
                </a>
                <span className="text-strong mb-3">Kết nối với Saymee</span>
              </div>
              <div className="d-flex">
                <a href="https://www.instagram.com/saymee.vn/" target="_blank" rel="noreferrer">
                  <Image src={IconIns} className="w-70" />
                </a>
                <a href="https://www.facebook.com/Saymee.vn" target="_blank" rel="noreferrer">
                  <Image src={IconFacebook} className="w-70" />
                </a>
                <a href="https://www.tiktok.com/@saymee.vn" target="_blank" rel="noreferrer">
                  <Image src={IconTiktok} className="w-70" />
                </a>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex flex-column">
                <span className="text-strong mb-3">Tải ngay app Saymee</span>
              </div>
              <div className="d-flex">
                <div className="col-5">
                  <a href="https://www.instagram.com/saymee.vn/" target="_blank" rel="noreferrer">
                    <Image src={QR} className="w-70" />
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a
                    href="https://www.instagram.com/saymee.vn/"
                    target="_blank"
                    rel="noreferrer"
                    className="mb-1"
                  >
                    <Image src={GooglePlay} className="w-70" />
                  </a>
                  <a href="https://www.instagram.com/saymee.vn/" target="_blank" rel="noreferrer">
                    <Image src={AppleStore} className="w-70" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="d-flex flex-row p-4">
            <div className="col-5">
              <Image src={LogoSM} className="w-70" />
            </div>
            <div className="col-7">
              <div className="d-flex">
                <span className="text-strong mb-3">Tải ngay app Saymee</span>
              </div>
              <div className="d-flex">
                <div className="col-5">
                  <a href="https://www.instagram.com/saymee.vn/" target="_blank" rel="noreferrer">
                    <Image src={QR} className="w-70" />
                  </a>
                </div>
                <div className="d-flex flex-column">
                  <a
                    href="https://www.instagram.com/saymee.vn/"
                    target="_blank"
                    rel="noreferrer"
                    className="mb-1"
                  >
                    <Image src={GooglePlay} className="w-70" />
                  </a>
                  <a href="https://www.instagram.com/saymee.vn/" target="_blank" rel="noreferrer">
                    <Image src={AppleStore} className="w-70" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
