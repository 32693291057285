import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const handleParseDate = ({ date, format }: { date: string | null; format: string }) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format(`${format}`);
};

export { handleParseDate };
