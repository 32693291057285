import React from 'react';
import { Button } from 'react-bootstrap';
import { handleParseDate } from 'Utils/ParseDate';
import { DATE_FORMAT_2 } from 'Constant/Date';

interface RowTypes {
  pickType?: string;
  quantity?: number;
  startDate: string;
  endDate: string;
  probability?: string;
  className?: string;
  isShowError?: boolean;
  handleEditItem?: () => void;
  handleRemoveItem?: () => void;
  isDisabled?: boolean;
}

const TableRowProbability = ({
  pickType,
  quantity,
  startDate,
  endDate,
  probability,
  className,
  handleEditItem,
  handleRemoveItem,
}: RowTypes): JSX.Element => {
  return (
    <tr className={`tr-drag ${className}`}>
      <td>{pickType}</td>
      <td>{quantity}</td>
      <td>{probability}%</td>
      <td>
        {handleParseDate({
          date: startDate,
          format: DATE_FORMAT_2,
        })}
      </td>
      <td>
        {handleParseDate({
          date: endDate,
          format: DATE_FORMAT_2,
        })}
      </td>
      <td>
        <div className="text-end">
          <Button onClick={handleEditItem} className="me-3">
            Chỉnh sửa
          </Button>
          <Button onClick={handleRemoveItem} className="btn-danger">
            Xóa
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TableRowProbability;
