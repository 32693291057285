import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  confirmPasswordValidation,
  passwordValidation,
  stringRequiredTrim,
} from 'Utils/Validation';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { AuthApi } from 'Datasource/Auth';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { useAppDispatch } from 'App/Store';
import { logout } from 'App/Features/Auth';

interface ChangePasswordTypes {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const schema = yup.object().shape({
  oldPassword: stringRequiredTrim({}),
  newPassword: passwordValidation({
    min: 8,
  }),
  confirmNewPassword: confirmPasswordValidation({ ref: 'newPassword' }),
});

export default function ResetPasswordForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordTypes>({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit(async (params: { oldPassword: string; newPassword: string }) => {
    if (await trigger()) {
      try {
        setLoading(true);
        await AuthApi.changePassword(params);
        setIsSuccess(true);
      } catch (error) {
        openMessageError(error);
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <form className="public-card-width" onSubmit={onSubmit}>
        {isSuccess ? (
          <>
            <p className="text-center mb-2">新しいパスワードの登録が完了しました。</p>
            <p className="text-center mb-3">ログイン出来ることをお確かめください。</p>
            <FormGroup className="mt-4 d-flex justify-content-center">
              <Button
                type="submit"
                className="bg-black border-0"
                onClick={() => dispatch(logout())}
              >
                ログイン画面へ
              </Button>
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroupInput
              label="以前のパスワード"
              labelMd="12"
              colMd="12"
              register={register('oldPassword')}
              type="password"
              messageWrapper="ms-2 w-max-content"
              errorMessage={errors.oldPassword?.message}
              value={watch('oldPassword')}
              onChange={(value) => setValue('oldPassword', value.replace(/\s/g, ''))}
            />
            <FormGroupInput
              label="新しいパスワード"
              labelMd="12"
              colMd="12"
              register={register('newPassword')}
              type="password"
              messageWrapper="ms-2 w-max-content"
              errorMessage={errors.newPassword?.message}
              value={watch('newPassword')}
              onChange={(value) => setValue('newPassword', value.replace(/\s/g, ''))}
            />
            <FormGroupInput
              label="新しいパスワード確認用"
              labelMd="12"
              colMd="12"
              register={register('confirmNewPassword')}
              type="password"
              messageWrapper="ms-2 w-max-content"
              errorMessage={errors.confirmNewPassword?.message}
              value={watch('confirmNewPassword')}
              onChange={(value) => setValue('confirmNewPassword', value.replace(/\s/g, ''))}
            />
            <FormGroup className="mt-4 d-flex justify-content-center">
              <Button type="submit" className="bg-black border-0">
                パスワードを変更する
              </Button>
            </FormGroup>
          </>
        )}
      </form>
    </>
  );
}
