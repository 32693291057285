import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CalendarEvent } from 'react-bootstrap-icons';
import BRDatePicker from './DatePicker';
import MessageError from 'Components/Common/MessageError';

type CPDate = Date | null | undefined;

export interface CPDateRangePickerTypes {
  minDate?: Date;
  maxDate?: Date;
  startDate: CPDate;
  endDate: CPDate;
  onChangeStartDate: (date: CPDate) => void;
  onChangeEndDate: (date: CPDate) => void;
  dateFormat?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  errorStartDate?: string;
  errorEndDate?: string;
  showTimeSelect?: boolean;
  startDateDisabled?: boolean;
  endDateDisabled?: boolean;
  minStartDate?: CPDate;
  maxStartDate?: CPDate;
  minEndDate?: CPDate;
  maxEndDate?: CPDate;
}

const CPDateRangePicker = ({
  dateFormat,
  startDate,
  endDate,
  errorStartDate,
  errorEndDate,
  placeholderStart,
  placeholderEnd,
  onChangeStartDate,
  onChangeEndDate,
  showTimeSelect,
  startDateDisabled,
  endDateDisabled,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
}: CPDateRangePickerTypes): JSX.Element => {
  return (
    <div>
      <Row>
        <Col md="5" className="px-0">
          <div className="position-relative">
            <BRDatePicker
              dateFormat={dateFormat}
              timeFormat="HH:mm"
              placeholder={placeholderStart ?? ''}
              showTimeSelect={showTimeSelect}
              selected={startDate}
              onChangeDate={onChangeStartDate}
              minDate={minStartDate ? minStartDate : new Date()}
              maxDate={maxStartDate}
              disabled={startDateDisabled}
            />
            <CalendarEvent className="affix-icon" />
          </div>
          <MessageError message={errorStartDate} />
        </Col>
        <Col md="2">
          <div className="mx-3 fs-18 text-center">&#8764;</div>
        </Col>
        <Col md="5" className="px-0">
          <div className="position-relative">
            <BRDatePicker
              dateFormat={dateFormat}
              timeFormat="HH:mm"
              placeholder={placeholderEnd ?? ''}
              showTimeSelect={showTimeSelect}
              selected={endDate}
              onChangeDate={onChangeEndDate}
              minDate={minEndDate ? minEndDate : startDate}
              maxDate={maxEndDate}
              disabled={endDateDisabled}
            />
            <CalendarEvent className="affix-icon" />
          </div>
          <MessageError message={errorEndDate} />
        </Col>
      </Row>
    </div>
  );
};

export default CPDateRangePicker;
