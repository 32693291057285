import React from 'react';
import { Nav, NavItem, NavLink, Container } from 'react-bootstrap';
import { useAppSelector } from 'App/Store';
import { CampaignSteps } from 'Constant/Campaign';

const StepRegister = ({
  handleNextStep,
}: {
  handleNextStep: (step: number) => void;
}): JSX.Element => {
  const { step } = useAppSelector((state) => state.campaign);

  const tabSteps: { label: string; eventKey: number }[] = [
    {
      label: 'Tổng quan',
      eventKey: CampaignSteps.Overview,
    },
    {
      label: 'Phần thưởng',
      eventKey: CampaignSteps.SignUpRewards,
    },
    // {
    //   label: 'Điều kiện chiến thắng',
    //   eventKey: CampaignSteps.WinningConditions, // comment for hide blacklist
    // },
    {
      label: 'Xác nhận chi tiết đăng ký',
      eventKey: CampaignSteps.Confirm,
    },
  ];

  return (
    <div className="register-campaign">
      <Container fluid className="shadow-sm">
        <div className="py-4 d-flex align-items-center justify-content-center">
          <Nav variant="pills" activeKey={step} className="nav-step justify-content-center">
            {tabSteps.map((item, index) => (
              <NavItem key={index} className="item-step position-relative bg-gray">
                <NavLink
                  className="h-100 w-100 d-flex align-items-center justify-content-center"
                  eventKey={item.eventKey}
                  onClick={() => {
                    handleNextStep(item.eventKey);
                  }}
                >
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </Container>
    </div>
  );
};

export default StepRegister;
