import React from 'react';
import LoginForm from 'Components/Page/Login/Form';
import { Container, Image } from 'react-bootstrap';
import Logo from 'Static/Images/logo.png';

export default function Login(): JSX.Element {
  return (
    <div className="bg-public position-relative">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-white">
        <h4 className="text-center mb-5">
          <Image src={Logo} style={{ width: '18%' }} />
        </h4>
        <LoginForm />
      </Container>
      <div className="w-100 text-center p-2 position-absolute bottom-0">
        <p className="mt-2 fs-7">Copyright ©2023 Dxcon Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
