/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { useQueryParams, NumberParam } from 'use-query-params';
import VoucherTable from 'Components/Page/Voucher/VoucherTable';

export default function VoucherList(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const history = useHistory();
  const { openMessageError } = useMessage();
  const [query] = useQueryParams({
    page: NumberParam,
  });

  const handleGetVoucher = async () => {
    try {
      setLoading(true);
      await new Promise((r) => setTimeout(r, 1000));
      const data = {
        items: [
          {
            id: 1,
            name: 'voucher 1',
            amount: 60,
            amountUsed: 10,
            brand: 'Got it',
          },
          {
            id: 2,
            name: 'voucher 2',
            amount: 100,
            amountUsed: 10,
            brand: 'Got it',
          },
          {
            id: 3,
            name: 'voucher 3',
            amount: 2000,
            amountUsed: 200,
            brand: 'Got it',
          },
        ],
        totalItems: 2,
      };

      setData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetVoucher();
  }, [query?.page]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8" className="d-flex justify-content-start px-4 pt-4">
            <Button
              onClick={() => history.push(`admin/vouchers/register`)}
              className="btn-focus-none px-3 button-submit"
            >
              Tạo voucher
            </Button>
          </Col>
        </Row>
        <div className="px-4">
          <SpinnerComponent isLoading={loading} />
          <Row className="my-3">
            <Col md={12} className="d-flex justify-content-end p-0">
              <PaginationComponent
                activePage={query?.page ?? 1}
                total={data?.totalItems ?? 0}
                perPage={PER_PAGE}
              />
            </Col>
          </Row>
          <VoucherTable dataSource={data.items || []} />
        </div>
      </Container>
    </>
  );
}
