import React, { PropsWithChildren } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select, {
  components as componentContent,
  GroupTypeBase,
  ValueContainerProps,
} from 'react-select';
import { FormGroupSelectProps } from 'Components/Common/Form/form';
import { Search as IconSearch } from 'react-bootstrap-icons';
import { ISelect } from 'Types/Common';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';

export default function FormGroupSelect(props: FormGroupSelectProps): JSX.Element {
  const {
    label,
    classLabel,
    options,
    onChange,
    defaultValue,
    classNameSlc,
    placeholder,
    errorMessage,
    labelMd,
    colMd,
    colLg,
    labelLg,
    rowClass,
    colClass,
    isMulti,
    register,
    disabled = false,
    isReview = false,
    isSearch,
    value,
    classBoxErr,
    onClickError,
    hintText,
  } = props;

  const ValueContainer = ({
    children,
    ...props
  }: PropsWithChildren<ValueContainerProps<ISelect, boolean, GroupTypeBase<ISelect>>>) => {
    return (
      componentContent.ValueContainer && (
        <componentContent.ValueContainer {...props}>
          {!!isSearch && (
            <div>
              <IconSearch />
            </div>
          )}
          <div className="ps-1">{children}</div>
        </componentContent.ValueContainer>
      )
    );
  };

  return (
    <Form.Group as={Row} className={rowClass}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {labelRequire(label)}
        </Form.Label>
      )}
      <Col md={colMd} lg={colLg} className={colClass}>
        <Select
          {...register}
          isMulti={isMulti}
          menuPlacement="auto"
          options={options}
          className={`w-100 ${classNameSlc ?? ''}`}
          placeholder={placeholder}
          onChange={!isReview ? onChange : () => null}
          defaultValue={defaultValue}
          value={value}
          components={{
            IndicatorSeparator: () => null,
            ValueContainer,
          }}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 1200,
            }),
          }}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          noOptionsMessage={() => '検索結果がありません。'}
        />
        <div onClick={onClickError} aria-hidden="true" className={classBoxErr}>
          <MessageError message={errorMessage} />
        </div>
        <div className="fs-12 mt-1 w-max-content">{hintText}</div>
      </Col>
    </Form.Group>
  );
}
