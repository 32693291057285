/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface VerifySpin {
  code: string;
  campaignId: any;
}
interface Spin {
  code: string;
  campaignId: any;
}

interface VerifyPhone {
  phoneNumber: string;
  campaignId: any;
  isSaymee: boolean;
  personalID: string;
}

export const LandingPageApis = {
  getConfigCampaign: ({ id }: { id: string }) => brAxios.get(`/config-campaign/${id}`),
  verifyPhone: (params: VerifyPhone) => brAxios.post('/verify-phone', params),
  verify: (params: VerifySpin) => brAxios.post('/verify', params),
  spinRealtime: (params: Spin) => brAxios.post('/spin-realtime', params),
  getUserWin: ({ id }: { id: string }) => brAxios.get(`/user-win/${id}`),
};
