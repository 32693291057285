import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function VoucherTable({ dataSource }: { dataSource: IListCD }): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            width: '100',
          },
          {
            Header: 'Tên',
            accessor: 'name',
            width: '250',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <Link
                  to={{
                    pathname: `/vouchers/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.name && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start">{row.original.name}</span>
                </Link>
              );
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.name ?? defaultVal).localeCompare(
                rowB?.original?.name ?? defaultVal,
              );
            },
          },
          {
            Header: 'Thương hiệu',
            accessor: 'brand',
            width: '300',
          },
          {
            Header: 'Số lượng',
            accessor: 'quantity',
            width: '300',
          },
          {
            Header: 'Số lượng đã sử dụng',
            accessor: 'amountUsed',
            width: '300',
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <TableCommon
        classTable="table-serial-number"
        heightBody="calc(100vh - 200px)"
        data={dataSource}
        columns={columns}
        messageNoData="Không có kết quả tìm kiếm."
      />
    </>
  );
}
