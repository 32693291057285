import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
  titlePage: string;
}

const initialState: LayoutState = {
  titlePage: '画⾯名称',
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTitlePage: (state, data: PayloadAction<string>) => {
      const { payload } = data;
      state.titlePage = payload;
    },
  },
});

export const { setTitlePage } = layoutSlice.actions;
export default layoutSlice.reducer;
