const DATE_FORMAT_1 = 'YYYY/MM/DD';
const DATE_FORMAT_2 = 'YYYY/MM/DD HH:mm';
const DATE_FORMAT_3 = 'YYYY年MM月DD日発売';
const DATE_FORMAT_4 = 'YYYY-MM-DD';
const DATE_FORMAT_5 = 'YYYY年MM月';
const DATE_FORMAT_6 = 'YYYY/MM/DD  HH:mm:ss';
const DATE_FORMAT_7 = 'YYYY-MM-DDTHH:mm:ss[Z]';
const DATE_FORMAT_8 = 'YYYY年MM月DD日';
const DATE_FORMAT_9 = 'YYYY年MM月DD日 HH:mm:ss';
const DATE_FORMAT_10 = 'MM/DD HH:mm';
const DATE_FORMAT_11 = 'M/DD';
const DATE_FORMAT_12 = 'ddd';
const DATE_FORMAT_13 = 'M/D';
const DATE_FORMAT_14 = 'M月/D日';
const DATE_FORMAT_15 = 'YYYY年MM月DD日 hh:mm';
const DATE_FORMAT_16 = 'MM月DD日 hh:mm';
const DATE_FORMAT_17 = 'M月D日';
const DATE_FORMAT_18 = 'YYYY年MM月DD日 HH:mm';
const DATE_FORMAT_19 = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT_FOR_DATE_PICKER = 'yyyy/MM/dd HH:mm';
const DATE_FORMAT_FOR_DATE_PICKER_1 = 'yyyy年MM月dd日発売';
const DATE_FORMAT_FOR_DATE_PICKER_2 = 'yyyy/MM/dd';

const DATE_FORMAT_YMD = 'YYYYMMDD';

const MAX_DATE = '9999-01-01';
const TIME_FORMAT = 'HH:mm';
const TIME_FORMAT_2 = 'hh:mmに通知';
export {
  DATE_FORMAT_1,
  DATE_FORMAT_2,
  DATE_FORMAT_3,
  DATE_FORMAT_4,
  DATE_FORMAT_5,
  DATE_FORMAT_6,
  DATE_FORMAT_7,
  DATE_FORMAT_8,
  DATE_FORMAT_9,
  DATE_FORMAT_10,
  DATE_FORMAT_11,
  DATE_FORMAT_12,
  DATE_FORMAT_13,
  DATE_FORMAT_14,
  DATE_FORMAT_15,
  DATE_FORMAT_16,
  DATE_FORMAT_17,
  DATE_FORMAT_18,
  DATE_FORMAT_19,
  DATE_FORMAT_FOR_DATE_PICKER,
  DATE_FORMAT_FOR_DATE_PICKER_1,
  DATE_FORMAT_FOR_DATE_PICKER_2,
  DATE_FORMAT_YMD,
  MAX_DATE,
  TIME_FORMAT,
  TIME_FORMAT_2,
};
