/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
export const ManagerApis = {
  getAll: ({ limit, page }: { limit?: number; page?: number }) =>
    brAxios.get(`/admins?page=${page ?? 1}&limit=${limit ?? 20}`),
  getDetail: ({ id }: { id: string }) => brAxios.get(`/admins/${id}`),
  create: ({ params }: { params: any }) => brAxios.post('/admins', params),
  update: ({ id, params }: { id: string; params: any }) => brAxios.put(`/admins/${id}`, params),
  updateProfile: ({ params }: { params: any }) => brAxios.patch(`/admins/profile`, params),
};
