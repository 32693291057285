import React, { memo, useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { ColSpec } from 'Components/Common/Form/form';
import { CheckboxOption } from 'Types/Common';
import { UseFormRegisterReturn } from 'react-hook-form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import HintTextField from 'Components/Common/Form/HintTextField';
// import { isEmpty, isEqual, xorWith } from 'lodash';

interface Props {
  id: string;
  name?: string;
  labelForm?: string | null;
  classForm?: string | null;
  errorMessage?: string;
  options: CheckboxOption[];
  labelMd?: ColSpec;
  colMd: ColSpec;
  onChange?: (option: CheckboxOption) => void;
  value?: string | number | undefined | null;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  isReview?: boolean;
  colClass?: string;
  classBoxErr?: string;
  classOption?: string;
  checkboxClass?: string;
  onClickError?: () => void;
  hintText?: string;
}

function CheckBoxs(props: Props): JSX.Element {
  const {
    id,
    name,
    labelForm,
    classForm,
    colClass,
    errorMessage,
    options,
    labelMd,
    colMd,
    onChange,
    value,
    register,
    disabled = false,
    classBoxErr,
    classOption = '',
    checkboxClass,
    onClickError,
    hintText = '',
  } = props;

  const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption[]>(options);

  const handleOnchange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    item: CheckboxOption,
  ) => {
    if (!onChange) {
      return;
    }
    const checked = e.currentTarget.checked;
    let options = [...checkboxOptions];
    options = options.map((option, optionKey) => ({
      ...option,
      checked: optionKey === index ? checked : false,
    }));
    onChange({ ...item, checked });
    setCheckboxOptions(options);
  };

  useEffect(() => {
    setCheckboxOptions([...options]);
  }, [options]);

  return (
    <Form.Group as={Row} className={`${classForm ?? ''} `}>
      {labelForm && (
        <Form.Label column md={labelMd}>
          {labelRequire(labelForm)}
        </Form.Label>
      )}
      <Col md={colMd} className={`align-self-center ${colClass} `}>
        <div
          className={`d-flex w-100 align-items-center box-checkBox flex-wrap ${checkboxClass} ${
            disabled
              ? 'pe-none user-select-none opacity-50'
              : 'pe-auto user-select-auto opacity-100'
          }`}
        >
          {checkboxOptions?.map((item, index) => {
            return (
              <Form.Check
                {...register}
                key={index}
                inline
                label={item.label}
                name={name || 'check-box-group'}
                type="radio"
                value={item.value}
                isValid={!!item.subLabel}
                checked={value !== undefined ? Number(value) === Number(item.value) : item.checked}
                onChange={(e) => handleOnchange(e, index, item)}
                id={`${id}-${index}`}
                feedback={item.subLabel}
                className={`check-box-ct mb-0 ${classOption}`}
                disabled={item.disabled}
              />
            );
          })}
        </div>
        <HintTextField hintText={hintText} />
        <div className={classBoxErr} onClick={onClickError} aria-hidden="true">
          <MessageError message={errorMessage} />
        </div>
      </Col>
    </Form.Group>
  );
}

export default memo(CheckBoxs);
