import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailValidation, stringRequiredTrim } from 'Utils/Validation';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import { RoleOptionsType } from 'Constant/Manger';
import { useAppSelector } from 'App/Store';
import { find } from 'lodash';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ManagerApis } from 'Datasource/Manager';
import useMessage from 'Hooks/useMessage';

export interface UserInfo {
  name: string;
  email: string;
  phone: string;
  role: string;
}

const schema = yup.object().shape({
  name: stringRequiredTrim({}),
  email: emailValidation({}),
  phone: stringRequiredTrim({}),
});

export default function UserInfo(): JSX.Element {
  const { auth } = useAppSelector((state) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { openMessageError, openMessage } = useMessage();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onsubmit = async (values: any) => {
    console.log(1222);
    setIsLoading(true);
    try {
      await ManagerApis.updateProfile({ params: { ...values } });
      openMessage({
        variant: 'success',
        message: 'Cập nhật thành công',
      });
    } catch (error) {
      console.log(error);
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const role = find(RoleOptionsType, { value: auth.role });
    const userInfo = { name: auth.name, email: auth.email, phone: auth.phone, role };
    reset(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <Container className="mt-4">
      <SpinnerComponent isLoading={isLoading} />
      <Row>
        <Col md="12" className="px-0">
          <BoxForm title="Cập nhật thông tin tài khoản">
            <>
              <Form onSubmit={handleSubmit(onsubmit)} id="update-account">
                <FormGroupInput
                  value={watch('name')}
                  labelMd="2"
                  colMd="10"
                  label="Tên:"
                  register={{ ...register('name') }}
                  onChange={(value) => setValue('name', value)}
                  errorMessage={errors.name?.message}
                />
                <FormGroupInput
                  value={watch('email')}
                  labelMd="2"
                  colMd="10"
                  label="Email:"
                  onChange={(value) => setValue('email', value)}
                  register={{ ...register('email') }}
                  errorMessage={errors.email?.message}
                />
                <FormGroupInput
                  value={watch('phone')}
                  labelMd="2"
                  colMd="10"
                  label="Số điện thoại:"
                  onChange={(value) => setValue('phone', value)}
                  register={{
                    ...register('phone'),
                  }}
                  errorMessage={errors.phone?.message}
                  maxLength={13}
                />
                <FormGroupSelect
                  rowClass="mb-2"
                  label="Vai trò:"
                  labelMd="2"
                  colMd="4"
                  value={watch('role')}
                  options={RoleOptionsType}
                  placeholder="Vui lòng chọn một giá trị"
                  onChange={(data) => {
                    setValue('role', data);
                  }}
                  disabled
                />
              </Form>
            </>
          </BoxForm>

          <div className="d-flex justify-content-center pb-4">
            <Button
              className="mt-4 mb-4 btn-focus-none btn-curator-register"
              variant="primary"
              form="update-account"
              onClick={() => handleSubmit(onsubmit)()}
            >
              Lưu lại
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
